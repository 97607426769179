.box-contact-branche
	margin 70px 0 90px
	overflow hidden
	h2
		font-size 20px
	&__header
		padding 23px 21px 19px
		margin 0 0 60px
		background #57524c
		overflow hidden
		width 100%
		color #fff
		box-sizing border-box
		line-height 24px
		display flex
		flex-wrap wrap
		justify-content space-between
		gap 5px 20px
	&__heading
		@extend h3
		margin 0
		font-size 20px
	&__link
		color #fff
		border 0 !important
		&:hover
			color #e3e3e3
		.icon
			margin-left 10px
	&__address
		margin 0 0 60px
	&__highlight
		color $pink
		font-family $fontHeavy
		margin 0 0 60px
		p
			margin 0 0 10px
	&__hours
		margin 0 0 60px
		h2
		p
			margin 0 0 20px
	&__map
		margin 0 0 30px
		text-align center
	&__map-link
		text-align center
		margin 0 0 60px
		text-transform uppercase
		font-size 13px
		font-family $fontHeavy
	&__parking
		margin 0 0 60px

	@media(max-width 749px)
		&__header
		&__address
		&__map-link
		&__highlight
		&__hours
		&__parking
			margin 0 0 40px
