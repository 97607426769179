.box-side-content
	float right
	width 325px
	margin-top 25px
	margin-bottom 50px
	margin-left 45px
	padding 47px 20px 27px
	overflow hidden
	box-sizing border-box
	background #ededed
	background linear-gradient(to bottom, #ededed, #f7f7f7)
	font-size 15px
	text-align center
	strong
	.bold
		font-size 14px
	p
		margin-bottom 1.6em
		&.box-side-content__heading--large
			margin-bottom 40px
	body:not(.page-com)
		a
			color $pink
			border-bottom 1px solid transparent
			transition color .3s, border-color .3s
			&:hover
				border-color $pinkHover
				color $pinkHover
	ul
		margin-bottom 44px
		text-align left
		li
			margin-bottom 19px
			line-height 23px
			background-image url($img'bg/li-pink.png')
	&__image
		margin 30px -20px 20px
		text-align center
		cursor pointer
		a
			display block
			position relative
			overflow hidden
			border 0 !important
			&:hover
				img
					transform scale(1.05)
		&-side
			box-vertical()
			position absolute
			top 0
			right 0
			width 40px
			height 100%
			background rgba($pink, .7)
			background url('../img/bg/lupa.png') no-repeat center center rgba(193,39,94, .7)
			transform translateX(100%)
			transition transform .2s ease-out
		&:hover &-side
			transform translateX(0)
		&-info
			display block
			margin-top 8px
			font-size 14px
			font-style italic
			text-align center
		img
			transition transform .5s ease-out
	&__heading
		display block
		position relative
		padding 0 40px
		font-size 16px
		&:before
		&:after
			content ''
			position absolute
			top 50%
			width 50px
			height 1px
			margin-top -2px
			background #d5d5d5
		&:before
			right 100%
			margin-right -30px
		&:after
			left 100%
			margin-left -30px
		&--large
			margin-top 1.6em
			margin-bottom 1.5em
			margin-bottom 40px
			color $pink
			font-size 20px
			line-height 1.3
			letter-spacing -0.01em
			text-transform uppercase
			&:before
			&:after
				top 10px
				top 50%
				margin-top 0
				margin-top auto
				transform translateY(-50%)

	&__block
		padding 0 5px
	&__extra
		font-weight bold
		margin 4em 0 2em
		font-size 14px
		p&
			margin @margin
	+ .box-content
		margin-right 325px
		.box-content__full
			margin-right -325px

	&--carousel
		padding 0 20px

	&__image--second
		padding-top 30px
		position relative
		&::before
			content ''
			position absolute
			left 0
			right 0
			top 0
			height 30px
			background #fff

	@media(min-width 1280px)
		width 360px
		// margin-left 0
		&__extra
			margin 4em 50px 2em
		+ .box-content
				margin-right 360px
				.box-content__full
					margin-right -355px

	@media(min-width 1440px)
		+ .box-content
			margin-right 405px
			.box-content__full
				margin-right 0


	@media(max-width 999px)
		margin-left 20px

	@media(max-width 899px)
		float none
		width auto
		margin 0
		padding 40px 20px
		> :last-child
			margin-bottom 0
		&__image
			max-width 480px
			margin-left auto
			margin-right auto
			img
				width 100%
				object-fit cover
		&__image:first-child
			margin-top -40px
			img
				max-height 182px
		&__heading
			margin-bottom 20px !important

		&__image--second
			&::before
				left -220px
				right -220px
		+ .box-content
			margin-right 0
			.box-content__full
				margin-right 0
