.menu-lang
	position absolute
	top 32px
	right 30px
	width 140px
	border-radius 2px
	padding 5px
	&__btn
		width 100%
		appearance none
		display flex
		border none
		background #efefef
		border-radius 2px
		color #787978
		padding 8px 0
		margin 0
		font-size 12px
		line-height 20px
		text-transform uppercase
		transition background .3s
		font-weight bold
		&-initials
			width 55px
			text-align center
			border-right 1px solid #e2e2e2
		&-full
			padding-left 16px
		&:hover
			background #e3e3e3
	&__list
		display none
	&__list &__btn
		margin-top 5px
		font-weight normal

	// STATES
	&.is-open
		background #efefef
	&.is-open &__list
		display block

	@media(max-width 1523px)
		right 35px

	@media(max-width 899px)
		display none
