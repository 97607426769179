.form-std
	font-size 15px
	&__btn-wrap
		margin-bottom -20px
	&__button
		inline-block(middle)
		margin 0
		padding 0
		border none
		background none
		text-decoration none
		color $green
		text-transform uppercase
		font-size 20px
		font-weight 500
		transition color .3s
		.icon
			transition transform .3s
		&:hover
		&:focus
			color $greenHover
			.icon
				transform translateY(5px)
		&--pink
			color #ac2857
			&:hover
				color #ac2857

	.box-bubble
		float left
		margin 0 20px 0 0

	&__row
		position relative
		margin-bottom 1.8em
		&--textarea
			.box-bubble
				position absolute
				top 15px
				right 15px
				margin 0

	&__label
		margin-right 20px
		text-transform uppercase
		img
			display block
		&--antispam
			margin-top -8px
			font-size 13px
			text-transform none
			strong
				display block
				text-align right

	@media(min-width 750px)
		&__row
			display flex
		&__label
			flex 0 0 auto
		.inp-fix
			flex 1 1 auto
