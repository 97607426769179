.box-person2
	font-size 15px
	text-align center
	text-decoration none
	color #474747
	display block
	border none !important
	max-width 260px
	margin 0 auto
	&__img
		display block
		position relative
		overflow hidden
		border-radius 100%
		margin 0 0 15px
		&::before
			content ''
			display block
			padding-top 100%
		img
			position absolute
			left 0
			top 0
			width 100%
			height 100%
			image-rendering optimizeQuality
			transition transform .5s ease-out
	&__hover
		position absolute
		left 0
		bottom 0
		right 0
		top 0
		background rgba(#c1275e, .8)
		z-index 1
		transform translate(-100%, 100%)
		border-radius 100%
		transition transform .3s ease-out
		.icon
			position absolute
			right 21%
			top 14%
	&__heading
		margin 0
		font-size 16px
		font-family $fontMedium
	&__about
		margin 0
		span
			display block
	&:hover
		color #474747
		text-decoration none
		border none
	&:hover &__hover
		transform translate(-50%, 65%)
	&:hover &__img
		img
			transform scale3d(1.05,1.05,1.05)
