//@-ms-viewport
//	width device-width

// Corrects block display not defined in IE6/7/8/9 & FF3
article
aside
details
figcaption
figure
footer
header
hgroup
nav
section
summary
	display block

// Corrects inline-block display not defined in IE6/7/8/9 & FF3
audio
canvas
video
	display inline-block
	*display inline
	zoom 1

// Prevents modern browsers from displaying 'audio' without controls
audio:not([controls])
	display none

// Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
// Known issue: no IE6 support
[hidden]
	display none


// =============================================================================
//  Base
// =============================================================================

// 1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
//    http://clagnut.com/blog/348/#c790
// 2. Keeps page centred in all browsers regardless of content height
// 3. Prevents iOS text size adjust after orientation change, without disabling user zoom
//    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
html
	font-size 100%
	overflow-y scroll
	-webkit-font-smoothing antialiased
	-moz-osx-font-smoothing grayscale
	-webkit-text-size-adjust 100%
	-ms-text-size-adjust 100%

html
button
input
select
textarea
	font-family sans-serif

// Addresses margins handled incorrectly in IE6/7
body
	margin 0

// =============================================================================
//  Links
// =============================================================================

// Addresses outline displayed oddly in Chrome
a:focus
	outline none

// =============================================================================
//  Typography
// =============================================================================

// Addresses font sizes and margins set differently in IE6/7
// Addresses font sizes within 'section' and 'article' in FF4+, Chrome, S5
h1
	font-size 2em
	margin 0.67em 0

h2
	font-size 1.5em
	margin 0.83em 0

h3
	font-size 1.17em
	margin 1em 0

h4
	font-size 1em
	margin 1.33em 0

h5
	font-size 0.83em
	margin 1.67em 0

h6
	font-size 0.75em
	margin 2.33em 0

// Addresses styling not present in IE7/8/9, S5, Chrome
abbr[title]
	border-bottom 1px dotted

// Addresses style set to 'bolder' in FF3+, S4/5, Chrome
b
strong
	font-weight bold

blockquote
	margin 1px 40px

// Addresses styling not present in S5, Chrome
dfn
	font-style italic

mark
	background #ff0
	color #000

//Addresses margins set differently in IE6/7
p
pre
	margin 1em 0

// Corrects font family set oddly in IE6, S4/5, Chrome
// en.wikipedia.org/wiki/User:Davidgothberg/Test59
pre
code
kbd
samp
	font-family monospace, serif
	_font-family 'courier new', monospace
	font-size 1em

// Improves readability of pre-formatted text in all browsers
pre
	white-space pre
	white-space pre-wrap
	word-wrap break-word

// Addresses CSS quotes not supported in IE6/7
q
	quotes none

// Addresses quote property not supported in S4
q:before
q:after
	content ''
	content none

small
	font-size 75%

// Prevents sub and sup affecting line-height in all browsers
// gist.github.com/413930
sub
sup
	font-size 75%
	line-height 0
	position relative
	vertical-align baseline

sup
	top -0.5em

sub
	bottom -0.25em

// =============================================================================
//  Lists
// =============================================================================

// Addresses margins set differently in IE6/7
dl
menu
ol
ul
	margin 1em 0

dd
	margin 0 0 0 40px

// Addresses paddings set differently in IE6/7
menu
ol
ul
	padding	0 0 0 40px

// Corrects list images handled incorrectly in IE7
nav ul
nav ol
	list-style none
	list-style-image none

// =============================================================================
//  Embedded content
// =============================================================================

// 1. Removes border when inside 'a' element in IE6/7/8/9, FF3
// 2. Improves image quality when scaled in IE7
//    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
img
	border 0
	-ms-interpolation-mode bicubic

// Corrects overflow displayed oddly in IE9
svg:not(:root)
	overflow hidden

img
iframe
object
embed
	vertical-align middle

// =============================================================================
//  Figures
// =============================================================================

// Addresses margin not present in IE6/7/8/9, S5, O11
figure
	margin 0

// =============================================================================
//  Forms
// =============================================================================

// Corrects margin displayed oddly in IE6/7
form
	margin 0

fieldset
	border 0
	margin 0
	padding 0

// 1. Corrects font size not being inherited in all browsers
// 2. Addresses margins set differently in IE6/7, FF3/4, S5, Chrome
// 3. Improves appearance and consistency in all browsers
button
input
select
textarea
	font-size 100%
	margin 0
	vertical-align baseline
	*vertical-align middle
	box-sizing content-box
	outline none
	border-radius none
	box-shadow none

// Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
button
input
	line-height normal


// 1. Improves usability and consistency of cursor style between image-type 'input' and others
// 2. Corrects inability to style clickable 'input' types in iOS
// 3. Corrects inner spacing displayed oddly in IE7 without effecting normal text inputs
//    Known issue: inner spacing remains in IE6
button
input[type="button"]
input[type="reset"]
input[type="submit"]
	cursor pointer // 1
	-webkit-appearance button // 2
	*overflow visible // 2

// Re-set default cursor for disabled elements
button[disabled]
input[disabled]
	cursor default

// 1. Addresses box sizing set to content-box in IE8/9
// 2. Removes excess padding in IE8/9
// 3. Removes excess padding in IE7
//    Known issue: excess padding remains in IE6
input[type="checkbox"]
input[type="radio"]
	box-sizing border-box // 1
	padding 0 // 2
	*height 13px // 3
	*width 13px // 3


// 1. Addresses appearance set to searchfield in S5, Chrome
// 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
input[type="search"]
	-webkit-appearance textfield // 1

// Removes inner padding and search cancel button in S5, Chrome on OS X
input[type="search"]::-webkit-search-decoration
input[type="search"]::-webkit-search-cancel-button
	-webkit-appearance none


// Removes inner padding and border in FF3+
// www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons
button::-moz-focus-inner
input::-moz-focus-inner
	border 0
	padding 0

// 1. Removes default vertical scrollbar in IE6/7/8/9
// 2. Improves readability and alignment in all browsers
textarea
	overflow auto // 1
	vertical-align top // 2
	resize vertical // TK

label
	cursor default

// =============================================================================
//  Tables
// =============================================================================

// Remove most spacing between table cells
table
	border-collapse collapse
	border-spacing 0

caption
	text-align left
	caption-side top
th
	text-align left
