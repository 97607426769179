.box-contact
	p
	table.reset
		margin-bottom 1.5em
	table.reset
		width auto
		td
			padding 1px 15px
			&:first-child
				padding-left 0
	.box-side-content__image
		margin -40px -30px 4em 0
		border-radius 2px 0 0 2px
		overflow hidden

	&__links
		font-size 14px
		text-transform uppercase
		margin-left -35px
		a
			display inline-block
			vertical-align top
			margin-left 35px
			border 0 !important
		.icon
			margin-right 20px
		.icon--heart
			top -4px
		.icon--comment
			top -2px

	@media(min-width 1440px)
		.box-side-content__image
			margin-right -45px

	@media(max-width 999px)
		.box-side-content__image
			margin-right -20px

	@media(max-width 749px)
		.box-side-content__image
			margin-top 0

