.box-table-list
	&__header
		padding 23px 21px 19px
		background #57524c
		color #fff
		box-sizing border-box
		line-height 24px
		border-top-left-radius 2px
		border-top-right-radius 2px
		display flex
		flex-wrap wrap
		justify-content space-between
		gap 5px 20px
		&-link
			position relative
			color #fff
			border 0 !important
			&:hover
				color #e3e3e3
			.icon
				margin-left 10px
		&-heading
			margin 0
			font-size 20px

	&__table
		// overflow hidden nedávat skrz tooltipy
		border-bottom-left-radius 2px
		border-bottom-right-radius 2px
		margin-bottom 2em
		tbody
			font-size 16px
		td
		th
			padding-top 20px
			padding-bottom 17px
		.icon
			top -2px
		.right
			.medium
				white-space nowrap
				font-size 140%
				display inline-block
				vertical-align middle

	&__info
		padding 0 20px
		margin-bottom 45px
		margin-top -15px
		& + &
			margin-top 0
		&.last
			margin-top 55px

	@media(max-width 749px)
		margin-left -20px
		margin-right -20px
		&__header-heading
			margin-right 0
