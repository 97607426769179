.box-order
	position relative
	overflow hidden
	margin -20px -30px
	height 313px
	letter-spacing -0.01em
	&:before
	&:after
		content ''
		position absolute
		left 16px
		right 20px
		height 1px
		background url($img'bg/order-lines.png') 50% 0 no-repeat
		background-size 894px 1px
	&:before
		top 58px
	&:after
		bottom 60px
	&:first-child
		margin-top @margin[0]
	&__right
		position absolute
		top 18px
		right 40px
		z-index 1
	&__left
		margin-right 280px
		text-align center
		font-size 16px

	&__circle
		box-vertical()
		background #5b5751
		size 276
		font-size 24px
		color #fff
		border-radius 160px
		p
			margin-bottom .875em
		a
			color #fff
		strong
			display block
			font-size 20px
			color #cbcbca
			font-weight 400
		&--3
			font-size 20px
			p
				margin-bottom .8em
			strong
				font-size 16px
		&-inner
			position relative
			top -8px
			display inline-block
			vertical-align middle
			max-width 95%


	&__title
		p&-main
			position relative
			margin-bottom 1px
			padding-top 40px
			font-size 26px
			text-transform uppercase
		p&-sub
			margin-bottom 0
			font-size 18px
			font-weight 500

	&__bottom
		position relative
		margin-bottom 0
		padding 0 45px

	p&__time
		margin 43px 0 42px
		font-size 24px
		font-weight 300

// .box-order__cicle-inner
// 	display inline-block
// 	vertical-align middle
// 	max-width 95%
