.inp-text
	display block
	border 0
	border-bottom 2px dotted #dbdbdb
	padding 3px 0
	background none
	color $color
	font-family $font
	font-size 15px
	line-height 21px
	text-align right
	height 21px
	border-radius 0px
	appearance none
	width 100%
	box-sizing border-box
	.is-error &
		background #feecec
		border-color #e82f44
	+placeholder()
		opacity(1, false)
		color rgba($color, .5)
		font-style italic
	&:focus
		border-color #aaa
		+placeholder()
			color rgba(#000, 0)
	textarea&
		height auto
		border 0
		background #ededed
		text-align left
		padding 20px 50px 20px 18px
		+placeholder()
			opacity(1, false)
			color rgba($color, .5)
			font-style italic
		&:focus
			background darken(@background, 2%)


.inp-fix
	position relative
	display block
	.inp-text
		width 100%
	&--select
		&:after
			sprite2x-select-size()
			content ''
			position absolute
			top 6px
			right 2px
	&--focus
		.formError
			display none !important


.inp-select
	@extend .inp-text
	text-align right
	text-align-last right
	padding 0 18px 0 0
	background #f8f8f8
