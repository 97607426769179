.box-card
	display block
	overflow hidden
	border 0
	border-color transparent
	border-radius 2px
	&:hover
		border 0
		border-color transparent
		text-decoration none
		.box-card__hover
			transform translateX(100%)
		.box-card__img
			img
				transform scale(1.06)
	&__img
		position relative
		overflow hidden
		max-height 118px
		img
			width 100%
			display block
			transition transform .5s ease-out
	&__hover
		position absolute
		z-index 5
		width 37px
		left -37px
		height 100%
		background-color rgba(193,39,94, .8)
		transition transform .3s ease-out
	&__ico
		position absolute
		top 50%
		left 50%
		left 17px
		transform translateY(-50%)
		width 3px
		height 3px
		border-radius 1px
		background-color #fff
		&:before
		&:after
			content ""
			position absolute
			display block
			width 3px
			height 3px
			border-radius 1px
			background-color #fff
		&:before
			margin-left -8px
		&:after
			margin-left 8px
	&__heading
		position relative
		display flex
		margin 0
		padding 5px 46px 5px 32px
		height 78px
		align-items center
		font-size 15px
		line-height 20px
		letter-spacing -0.01em
		text-transform none
		color #474747
		font-weight 500
		background linear-gradient(#f0f0f0, #f5f5f5)
		.icon
			position absolute
			right 26px
			top 50%
			transform translateY(-50%)