$reset-ul
	margin 0

$reset-ul-li
	padding 0
	margin 0
	background none
	overflow visible
	background-size auto
	background-position auto auto

$reset-ol
	@extend $reset-ul

$reset-ol-li
	@extend $reset-ul-li
	position static
	&:before
		content normal
		position static
		left auto
		top auto

$grid
	font-size 0px
	text-align justify
	text-justify distribute-all-lines
	@media (-webkit-min-device-pixel-ratio 0)
		letter-spacing -1px

$grid__cell
	display inline-block
	vertical-align top
	font-size $fontSize
	text-align left
	box-sizing border-box
	letter-spacing normal
	white-space normal

$grid--scroll
	position relative
	white-space nowrap
	overflow hidden
	overflow-x auto
	.js &
		overflow-x hidden

grid($selector = 'col--', $separator = '-', $columns = 12, $rule = 'width', $coef = 1, $others = {})
	for $i in 1..$columns
		{$selector + $i + $separator + $columns}
			{$rule} unit(100 / $columns * $i * $coef, '%')
			{$others}

inline-block($align = top, $isBlock = true)
	display inline-block
	vertical-align $align
	if $isBlock && support-for-ie
		.ie7 &
			display inline
			zoom 1

inline-list($width, $fontSize)
	font-size 0px
	> *
		inline-block()
		if $width isnt false
			width $width
		font-size $fontSize

inline-list-scroll-extend()
	position relative
	white-space nowrap
	overflow hidden
	overflow-x auto
	> * > *
		white-space normal
	.js &
		overflow-x hidden

inline-list-scroll($width, $font)
	inline-list($width, $font)
	inline-list-scroll-extend()

// Clearování flotů
clearfix()
	zoom 1 if support-for-ie
	&:after
	&:before
		content ''
		display table
		clear both


// Skrytí textu
text-hide()
	font 0px/0px a
	color transparent
	text-decoration none

// Rozměry
size($width = false, $height = false)
	if unit($width) is ''
		$width = unit($width, 'px')

	if $height
		if unit($height) is ''
			$height = unit($height, 'px')
		width $width
		height $height
	else
		width $width
		height $width


// PX do EM
pxToEm($from, $to)
	unit(($to/$from), em)

// Vertikální zarovnání obsahu
box-vertical($align = middle)
	&:before
	.before
		content ''
		display inline-block
		height 100%
		margin-right -0.25em
		vertical-align $align

// Převádí z Nib base64 do obrázku
to-image(url, base64)
	'url(%s)' % data-to-image(unquote(url), base64)

// Vytvoření trojúhelníku pomocí borderů
triangle(direction = 'down', width = 10px, height = 10px, color = #000)
	width 0
	height 0
	border-style solid

	directions = {
		up: {
			border-width: 0 (width * .5) height (width * .5),
			border-color: transparent transparent color transparent
		},
		down: {
			border-width: height (width * .5) 0 (width * .5),
			border-color: color transparent transparent transparent
		},
		left: {
			border-width: (height * .5) width (height * .5) 0,
			border-color: transparent color transparent transparent
		},
		right: {
			border-width: (height * .5) 0 (height *.5) width,
			border-color: transparent transparent transparent color
		},
		up-left: {
			border-width: height width 0 0,
			border-color: color transparent transparent transparent
		},
		down-left: {
			border-width: width 0 0 height,
			border-color: transparent transparent transparent color
		},
		up-right: {
			border-width: 0 width height 0,
			border-color: transparent color transparent transparent
		},
		down-right: {
			border-width: 0 0 height width,
			border-color: transparent transparent color transparent
		}
	}

	styles = directions[direction]
	{styles}


font-weight(weight)
	font-weight normal
	if weight == 300
		font-family $fontLight
	if weight == 400
		font-family $fontNormal
	if weight == 'normal'
		font-family $fontNormal
	if weight == 500
		font-family $fontMedium
	if weight == 700
		font-family $fontHeavy
	if weight == 'bold'
		font-family $fontHeavy
	if weight == 900
		font-family $fontHeavy
