.box-intro
	s = selector()
	position relative
	min-height 100px
	overflow hidden
	z-index 1
	&:after
		content ''
		position absolute
		top 0
		left 0
		width 100%
		height 10px
		background linear-gradient(to bottom, rgba(#000, .1), rgba(#000, 0))
	.row-main
		max-width 1180px
		height 100%
	p
		margin 0
		line-height 1.6
	&__list
		inline-list-scroll(100%, $fontSize)
		height 468px
	&__link
		position relative
		overflow hidden
		height 100%
		color #fff
		&:hover
			color @color
			{s}__inner
				background rgba(#c1275e, .88)
				&:before
					background rgba(#c1275e, .88)
			{s}__more .icon
				transform translateX(0)
				opacity 1
	&__bg
		position absolute
		top 0
		left 0
		width 100%
		height 100%
		background-size cover
		background-repeat no-repeat
		background-position 75% 50%
		&--stelcl
			background-image url('../img/illust/lide-intro-marcel-stelcl.jpg')
		&--moudry
			background-image url('../img/illust/lide-intro-ludek-moudry.jpg')
		&--hlouskova
			background-image url('../img/illust/lide-intro-lucie-hlouskova.jpg')
		&--rotter
			background-image url('../img/illust/lide-intro-leopold-rotter.jpg')
		&--lukasova
			background-image url('../img/illust/lide-intro-tereza-lukasova.jpg')
		&--spondrova
			background-image url('../img/illust/lide-intro-barbora-spondrova.jpg')
	&__title
		@extend h3
		position absolute
		right 40px
		bottom 35px
		margin 0
		color $color
		font-family $fontNormal
		text-transform none
		font-size 13px
		line-height 1.4
		p
			line-height 1.4
		strong
			font-family $fontRoman
			font-size 15px

	&__inner
		position relative
		width 50%
		box-sizing border-box
		margin 140px 0 0
		padding 25px 60px 30px 0
		background rgba(#c1275e, .8)
		color #fff
		transition background .3s
		br
			display none
		&:before
			content ''
			position absolute
			top 0
			right 100%
			width 500px
			height 100%
			background @background
			transition background .3s
		&:first-child
			margin-top @margin[0]

	&__heading
		margin 0
		font-size 36px
		line-height 1.4
		text-transform uppercase
		font-weight 300

	p&__more
		margin-top 10px
		font-size 13px
		font-weight 900
		text-transform uppercase
		color #fff
		.icon
			top -1px
			margin-left 10px
			transform translateX(-10px)
			opacity 0
			transition opacity .4s, transform .4s

	@media(max-width 900px)
		&__heading
			font-size 26px

	@media(max-width 799px)
		.row-main
		&__link
			height auto
		.row-main
			margin-top -30px
		&__list
			height auto
		&__bg
			position relative
			height 260px
			background-size cover
			background-position 50% 0
			&--stelcl
				background-image url('../img/illust/lide-intro-mobil-marcel-stelcl.jpg')
			&--moudry
				background-image url('../img/illust/lide-intro-mobil-ludek-moudry.jpg')
			&--hlouskova
				background-image url('../img/illust/lide-intro-mobil-lucie-hlouskova.jpg')
			&--rotter
				background-image url('../img/illust/lide-intro-mobil-leopold-rotter.jpg')
			&--lukasova
				background-image url('../img/illust/lide-intro-mobil-tereza-lukasova.jpg')
			&--spondrova
				background-image url('../img/illust/lide-intro-mobil-barbora-spondrova.jpg')
		&__heading
			font-size 21px
			font-weight 500
			margin 0 0 10px
		&__title
			bottom 100%
			margin-bottom 10px
			text-align left
			font-size 12px
			left 20px
			strong
				white-space nowrap
				font-size 14px
				font-family $fontMedium
		&__list > *
			font-size 15px
		p&__more
			margin-top 20px
		&__inner
			margin 0
			padding 20px 20px 20px 0
			width auto

		.page-subpage &__bg
		.page-com &__bg
			height 100px

	@media(min-width 800px)
		.row-main
			display flex
			align-items center
			padding-top 50px
			box-sizing border-box
		&__inner
			margin 0

	@media(min-width 900px)
		@media(max-width 999px)
			&__inner
				padding-left 20px


	@media(min-width 1280px)
		&__list
			height 536px
		&__title
			right 0
		&__inner
			padding 21px 110px 25px 0
