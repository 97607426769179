.box-sidebar
	@media(min-width 1440px)
		body:not(.page-com) &
			position relative
			float left
			width 310px
			padding 20px
			box-sizing border-box
			background #ededed
			padding-bottom 5000px
			margin-bottom -5000px
			z-index 3
			&:before
			&:after
				content ''
				position absolute
				bottom 100%
			&:before
				left 0
				width 100%
				height 5px
				background #64625b
				border-radius 2px 2px 0 0
			&:after
				left 50%
				width 70px
				height 2px
				margin-bottom 3px
				margin-left (@width/-2)
				background $green
				border-radius 0 0 1px 1px
