.box-content
	padding 45px 45px 30px
	&__text
		margin-bottom 60px
		p
			margin-bottom 40px
		li
			margin-bottom 40px
			padding-left 52px
			background url($img'bg/icon-check-m.png') no-repeat 0 5px
			background-size 22px 13px
	&__full
		clear right
	a
		border-bottom 1px solid transparent
		transition color .3s, border-color .3s
		&.box-card
		&.box-person
			border 0
		&:hover
			border-color $greenHover
	table a
		&:hover
			border-color transparent

	@media(min-width 1440px)
		body:not(.page-com) &
			margin-left 355px
			margin-right 45px
			padding 45px 0 70px
		.page-homepage:not(.page-com) &
			margin-left 0
			margin-right 0
			padding-left 45px
			padding-right 45px
			background url($img'bg/gradient-page.png') no-repeat 0 0
			//background linear-gradient(to bottom, #f7f7f7 113px, #fdfdfd 600px)
		.page-com &
			padding-left 100px
			padding-right 70px

	@media(max-width 999px)
		padding 40px 20px 20px
		.row-main
			padding-right 0
			padding-left 0

