/*!
 *	Base layout
 */
body
	background #3d3c3c
	background #363330
	position relative
	color $color
	min-width 320px

.page-background
	@media(min-width 1524px)
		background #9e9e9e
		padding 5px 0 60px

#mother
	max-width 1524px
	margin 0 auto
	overflow hidden
	background #fdfdfd
	background linear-gradient(to bottom, #f7f7f7 113px, #fdfdfd 600px)
	box-shadow 0 2px 10px rgba(#12110c, .25)
	@media(min-width 1524px)
		border-radius 2px


@import 'layout/*'

/*!
 *	Menu
 */
@import "layout/menu/*"

/*!
 *	Crossroads
 */
// @import "layout/crossroad/*"

/*!
 *	Box
 */
@import "layout/box/*"

/*!
 *	Form
 */
@import "layout/form/*"

:first-child
	margin-top 0
