.box-images
	margin-bottom 88px
	figcaption
		text-align center
		text-transform uppercase
		letter-spacing -0.01em
	&__list
		display flex
		flex-wrap wrap
		margin-left -22px
	&__item
		box-sizing border-box
		width 50%
		border-left 22px solid transparent
		margin-bottom 19px
		img
			margin-bottom 20px

	@media(max-width 479px)
		&__item
			width 100%
			img
				margin-bottom 10px
