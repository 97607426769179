sprite2x-arr-car-down()
	background url('../img/bg/sprites-retina.png') unit(-269 / 2, 'px') unit(-332 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-car-down-size()
	sprite2x-arr-car-down()
	size (62 / 2) (24 / 2)
sprite2x-arr-car-down-sizeonly()
	size (62 / 2) (24 / 2)
sprite2x-arr-car-down-position()
	background-position unit(-269 / 2, 'px') unit(-332 / 2, 'px')
sprite2x-arr-car-up()
	background url('../img/bg/sprites-retina.png') unit(-393 / 2, 'px') unit(-332 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-car-up-size()
	sprite2x-arr-car-up()
	size (62 / 2) (24 / 2)
sprite2x-arr-car-up-sizeonly()
	size (62 / 2) (24 / 2)
sprite2x-arr-car-up-position()
	background-position unit(-393 / 2, 'px') unit(-332 / 2, 'px')
sprite2x-arr-down-btn()
	background url('../img/bg/sprites-retina.png') unit(-331 / 2, 'px') unit(-332 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-down-btn-size()
	sprite2x-arr-down-btn()
	size (62 / 2) (24 / 2)
sprite2x-arr-down-btn-sizeonly()
	size (62 / 2) (24 / 2)
sprite2x-arr-down-btn-position()
	background-position unit(-331 / 2, 'px') unit(-332 / 2, 'px')
sprite2x-arr-down-pink()
	background url('../img/bg/sprites-retina.png') unit(-44 / 2, 'px') unit(-394 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-down-pink-size()
	sprite2x-arr-down-pink()
	size (44 / 2) (20 / 2)
sprite2x-arr-down-pink-sizeonly()
	size (44 / 2) (20 / 2)
sprite2x-arr-down-pink-position()
	background-position unit(-44 / 2, 'px') unit(-394 / 2, 'px')
sprite2x-arr-down-white()
	background url('../img/bg/sprites-retina.png') unit(-484 / 2, 'px') unit(-74 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-down-white-size()
	sprite2x-arr-down-white()
	size (26 / 2) (12 / 2)
sprite2x-arr-down-white-sizeonly()
	size (26 / 2) (12 / 2)
sprite2x-arr-down-white-position()
	background-position unit(-484 / 2, 'px') unit(-74 / 2, 'px')
sprite2x-arr-down()
	background url('../img/bg/sprites-retina.png') unit(-484 / 2, 'px') unit(-117 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-down-size()
	sprite2x-arr-down()
	size (20 / 2) (8 / 2)
sprite2x-arr-down-sizeonly()
	size (20 / 2) (8 / 2)
sprite2x-arr-down-position()
	background-position unit(-484 / 2, 'px') unit(-117 / 2, 'px')
sprite2x-arr-left-pink()
	background url('../img/bg/sprites-retina.png') unit(-436 / 2, 'px') unit(-144 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-left-pink-size()
	sprite2x-arr-left-pink()
	size (24 / 2) (62 / 2)
sprite2x-arr-left-pink-sizeonly()
	size (24 / 2) (62 / 2)
sprite2x-arr-left-pink-position()
	background-position unit(-436 / 2, 'px') unit(-144 / 2, 'px')
sprite2x-arr-right-bold()
	background url('../img/bg/sprites-retina.png') unit(-484 / 2, 'px') unit(0 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-right-bold-size()
	sprite2x-arr-right-bold()
	size (27 / 2) (38 / 2)
sprite2x-arr-right-bold-sizeonly()
	size (27 / 2) (38 / 2)
sprite2x-arr-right-bold-position()
	background-position unit(-484 / 2, 'px') unit(0 / 2, 'px')
sprite2x-arr-right-hover()
	background url('../img/bg/sprites-retina.png') unit(-492 / 2, 'px') unit(-95 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-right-hover-size()
	sprite2x-arr-right-hover()
	size (8 / 2) (20 / 2)
sprite2x-arr-right-hover-sizeonly()
	size (8 / 2) (20 / 2)
sprite2x-arr-right-hover-position()
	background-position unit(-492 / 2, 'px') unit(-95 / 2, 'px')
sprite2x-arr-right-more()
	background url('../img/bg/sprites-retina.png') unit(-484 / 2, 'px') unit(-95 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-right-more-size()
	sprite2x-arr-right-more()
	size (8 / 2) (22 / 2)
sprite2x-arr-right-more-sizeonly()
	size (8 / 2) (22 / 2)
sprite2x-arr-right-more-position()
	background-position unit(-484 / 2, 'px') unit(-95 / 2, 'px')
sprite2x-arr-right-pink()
	background url('../img/bg/sprites-retina.png') unit(-484 / 2, 'px') unit(-38 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-right-pink-size()
	sprite2x-arr-right-pink()
	size (20 / 2) (36 / 2)
sprite2x-arr-right-pink-sizeonly()
	size (20 / 2) (36 / 2)
sprite2x-arr-right-pink-position()
	background-position unit(-484 / 2, 'px') unit(-38 / 2, 'px')
sprite2x-arr-right()
	background url('../img/bg/sprites-retina.png') unit(-500 / 2, 'px') unit(-95 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-arr-right-size()
	sprite2x-arr-right()
	size (8 / 2) (20 / 2)
sprite2x-arr-right-sizeonly()
	size (8 / 2) (20 / 2)
sprite2x-arr-right-position()
	background-position unit(-500 / 2, 'px') unit(-95 / 2, 'px')
sprite2x-docinfo()
	background url('../img/bg/sprites-retina.png') unit(-185 / 2, 'px') unit(-332 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-docinfo-size()
	sprite2x-docinfo()
	size (42 / 2) (36 / 2)
sprite2x-docinfo-sizeonly()
	size (42 / 2) (36 / 2)
sprite2x-docinfo-position()
	background-position unit(-185 / 2, 'px') unit(-332 / 2, 'px')
sprite2x-icon-check-m()
	background url('../img/bg/sprites-retina.png') unit(0 / 2, 'px') unit(-394 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-check-m-size()
	sprite2x-icon-check-m()
	size (44 / 2) (26 / 2)
sprite2x-icon-check-m-sizeonly()
	size (44 / 2) (26 / 2)
sprite2x-icon-check-m-position()
	background-position unit(0 / 2, 'px') unit(-394 / 2, 'px')
sprite2x-icon-check()
	background url('../img/bg/sprites-retina.png') unit(-399 / 2, 'px') unit(-292 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-check-size()
	sprite2x-icon-check()
	size (50 / 2) (40 / 2)
sprite2x-icon-check-sizeonly()
	size (50 / 2) (40 / 2)
sprite2x-icon-check-position()
	background-position unit(-399 / 2, 'px') unit(-292 / 2, 'px')
sprite2x-icon-close-m()
	background url('../img/bg/sprites-retina.png') unit(0 / 2, 'px') unit(-420 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-close-m-size()
	sprite2x-icon-close-m()
	size (34 / 2) (32 / 2)
sprite2x-icon-close-m-sizeonly()
	size (34 / 2) (32 / 2)
sprite2x-icon-close-m-position()
	background-position unit(0 / 2, 'px') unit(-420 / 2, 'px')
sprite2x-icon-close-white()
	background url('../img/bg/sprites-retina.png') unit(-396 / 2, 'px') unit(-254 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-close-white-size()
	sprite2x-icon-close-white()
	size (40 / 2) (38 / 2)
sprite2x-icon-close-white-sizeonly()
	size (40 / 2) (38 / 2)
sprite2x-icon-close-white-position()
	background-position unit(-396 / 2, 'px') unit(-254 / 2, 'px')
sprite2x-icon-close()
	background url('../img/bg/sprites-retina.png') unit(-356 / 2, 'px') unit(-254 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-close-size()
	sprite2x-icon-close()
	size (40 / 2) (38 / 2)
sprite2x-icon-close-sizeonly()
	size (40 / 2) (38 / 2)
sprite2x-icon-close-position()
	background-position unit(-356 / 2, 'px') unit(-254 / 2, 'px')
sprite2x-icon-comment-hover()
	background url('../img/bg/sprites-retina.png') unit(-245 / 2, 'px') unit(-292 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-comment-hover-size()
	sprite2x-icon-comment-hover()
	size (52 / 2) (40 / 2)
sprite2x-icon-comment-hover-sizeonly()
	size (52 / 2) (40 / 2)
sprite2x-icon-comment-hover-position()
	background-position unit(-245 / 2, 'px') unit(-292 / 2, 'px')
sprite2x-icon-comment()
	background url('../img/bg/sprites-retina.png') unit(-297 / 2, 'px') unit(-292 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-comment-size()
	sprite2x-icon-comment()
	size (52 / 2) (40 / 2)
sprite2x-icon-comment-sizeonly()
	size (52 / 2) (40 / 2)
sprite2x-icon-comment-position()
	background-position unit(-297 / 2, 'px') unit(-292 / 2, 'px')
sprite2x-icon-cross()
	background url('../img/bg/sprites-retina.png') unit(-349 / 2, 'px') unit(-292 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-cross-size()
	sprite2x-icon-cross()
	size (50 / 2) (40 / 2)
sprite2x-icon-cross-sizeonly()
	size (50 / 2) (40 / 2)
sprite2x-icon-cross-position()
	background-position unit(-349 / 2, 'px') unit(-292 / 2, 'px')
sprite2x-icon-glass()
	background url('../img/bg/sprites-retina.png') unit(-141 / 2, 'px') unit(-332 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-glass-size()
	sprite2x-icon-glass()
	size (44 / 2) (44 / 2)
sprite2x-icon-glass-sizeonly()
	size (44 / 2) (44 / 2)
sprite2x-icon-glass-position()
	background-position unit(-141 / 2, 'px') unit(-332 / 2, 'px')
sprite2x-icon-heart-hover()
	background url('../img/bg/sprites-retina.png') unit(-193 / 2, 'px') unit(-292 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-heart-hover-size()
	sprite2x-icon-heart-hover()
	size (52 / 2) (40 / 2)
sprite2x-icon-heart-hover-sizeonly()
	size (52 / 2) (40 / 2)
sprite2x-icon-heart-hover-position()
	background-position unit(-193 / 2, 'px') unit(-292 / 2, 'px')
sprite2x-icon-heart()
	background url('../img/bg/sprites-retina.png') unit(-141 / 2, 'px') unit(-292 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-heart-size()
	sprite2x-icon-heart()
	size (52 / 2) (40 / 2)
sprite2x-icon-heart-sizeonly()
	size (52 / 2) (40 / 2)
sprite2x-icon-heart-position()
	background-position unit(-141 / 2, 'px') unit(-292 / 2, 'px')
sprite2x-icon-logo-bw()
	background url('../img/bg/sprites-retina.png') unit(0 / 2, 'px') unit(-292 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-logo-bw-size()
	sprite2x-icon-logo-bw()
	size (74 / 2) (102 / 2)
sprite2x-icon-logo-bw-sizeonly()
	size (74 / 2) (102 / 2)
sprite2x-icon-logo-bw-position()
	background-position unit(0 / 2, 'px') unit(-292 / 2, 'px')
sprite2x-icon-logo-m()
	background url('../img/bg/sprites-retina.png') unit(-74 / 2, 'px') unit(-292 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-logo-m-size()
	sprite2x-icon-logo-m()
	size (67 / 2) (92 / 2)
sprite2x-icon-logo-m-sizeonly()
	size (67 / 2) (92 / 2)
sprite2x-icon-logo-m-position()
	background-position unit(-74 / 2, 'px') unit(-292 / 2, 'px')
sprite2x-icon-logo()
	background url('../img/bg/sprites-retina.png') unit(-356 / 2, 'px') unit(-144 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-logo-size()
	sprite2x-icon-logo()
	size (80 / 2) (110 / 2)
sprite2x-icon-logo-sizeonly()
	size (80 / 2) (110 / 2)
sprite2x-icon-logo-position()
	background-position unit(-356 / 2, 'px') unit(-144 / 2, 'px')
sprite2x-icon-popup()
	background url('../img/bg/sprites-retina.png') unit(-227 / 2, 'px') unit(-332 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-icon-popup-size()
	sprite2x-icon-popup()
	size (42 / 2) (36 / 2)
sprite2x-icon-popup-sizeonly()
	size (42 / 2) (36 / 2)
sprite2x-icon-popup-position()
	background-position unit(-227 / 2, 'px') unit(-332 / 2, 'px')
sprite2x-leticia-hover()
	background url('../img/bg/sprites-retina.png') unit(-178 / 2, 'px') unit(-144 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-leticia-hover-size()
	sprite2x-leticia-hover()
	size (178 / 2) (148 / 2)
sprite2x-leticia-hover-sizeonly()
	size (178 / 2) (148 / 2)
sprite2x-leticia-hover-position()
	background-position unit(-178 / 2, 'px') unit(-144 / 2, 'px')
sprite2x-leticia()
	background url('../img/bg/sprites-retina.png') unit(0 / 2, 'px') unit(-144 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-leticia-size()
	sprite2x-leticia()
	size (178 / 2) (148 / 2)
sprite2x-leticia-sizeonly()
	size (178 / 2) (148 / 2)
sprite2x-leticia-position()
	background-position unit(0 / 2, 'px') unit(-144 / 2, 'px')
sprite2x-select()
	background url('../img/bg/sprites-retina.png') unit(-484 / 2, 'px') unit(-86 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-select-size()
	sprite2x-select()
	size (20 / 2) (9 / 2)
sprite2x-select-sizeonly()
	size (20 / 2) (9 / 2)
sprite2x-select-position()
	background-position unit(-484 / 2, 'px') unit(-86 / 2, 'px')
sprite2x-thickbox-left()
	background url('../img/bg/sprites-retina.png') unit(-460 / 2, 'px') unit(0 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-thickbox-left-size()
	sprite2x-thickbox-left()
	size (24 / 2) (46 / 2)
sprite2x-thickbox-left-sizeonly()
	size (24 / 2) (46 / 2)
sprite2x-thickbox-left-position()
	background-position unit(-460 / 2, 'px') unit(0 / 2, 'px')
sprite2x-thickbox-right()
	background url('../img/bg/sprites-retina.png') unit(-436 / 2, 'px') unit(-206 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-thickbox-right-size()
	sprite2x-thickbox-right()
	size (24 / 2) (46 / 2)
sprite2x-thickbox-right-sizeonly()
	size (24 / 2) (46 / 2)
sprite2x-thickbox-right-position()
	background-position unit(-436 / 2, 'px') unit(-206 / 2, 'px')
sprite2x-vascumed-hover()
	background url('../img/bg/sprites-retina.png') unit(-230 / 2, 'px') unit(0 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-vascumed-hover-size()
	sprite2x-vascumed-hover()
	size (230 / 2) (144 / 2)
sprite2x-vascumed-hover-sizeonly()
	size (230 / 2) (144 / 2)
sprite2x-vascumed-hover-position()
	background-position unit(-230 / 2, 'px') unit(0 / 2, 'px')
sprite2x-vascumed()
	background url('../img/bg/sprites-retina.png') unit(0 / 2, 'px') unit(0 / 2, 'px') no-repeat
	background-size unit(511 / 2, 'px') unit(452 / 2, 'px')
sprite2x-vascumed-size()
	sprite2x-vascumed()
	size (230 / 2) (144 / 2)
sprite2x-vascumed-sizeonly()
	size (230 / 2) (144 / 2)
sprite2x-vascumed-position()
	background-position unit(0 / 2, 'px') unit(0 / 2, 'px')
