.inp-item
	display block
	cursor pointer
	&__inp
		position absolute
		left -5000px
	&__text
		padding-left 45px
		position relative
		display block
		&::before
		&::after
			content ''
			position absolute
			left 0
			top 50%
			width 26px
			height 24px
			margin-top -12px
		&::before
			background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='24'%3E%3Cpath fill='%23b4b4b4' d='M12.981,23.792 L12.524,23.998 L12.066,23.792 C6.183,20.084 -0.001,11.641 -0.001,6.953 C0.019,3.114 3.076,-0.003 6.831,-0.003 C9.204,-0.003 11.299,1.243 12.524,3.128 C13.749,1.243 15.843,-0.003 18.217,-0.003 C21.972,-0.003 25.028,3.114 25.048,6.953 C25.048,11.641 18.864,20.084 12.981,23.792 ZM18.217,1.163 C16.308,1.163 14.534,2.139 13.472,3.774 L12.524,5.233 L11.576,3.774 C10.514,2.139 8.740,1.163 6.831,1.163 C3.709,1.163 1.155,3.763 1.138,6.953 C1.138,11.089 6.964,19.134 12.524,22.711 C18.083,19.134 23.910,11.089 23.910,6.959 C23.893,3.763 21.339,1.163 18.217,1.163 Z'/%3E%3C/svg%3E")
		&::after
			opacity 0
			transition opacity .3s
			background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='24'%3E%3Cpath fill='%23b4b4b4' d='M12.981,23.792 L12.524,23.998 L12.066,23.792 C6.183,20.084 -0.001,11.641 -0.001,6.953 C0.019,3.114 3.076,-0.003 6.831,-0.003 C9.204,-0.003 11.299,1.243 12.524,3.128 C13.749,1.243 15.843,-0.003 18.217,-0.003 C21.972,-0.003 25.028,3.114 25.048,6.953 C25.048,11.641 18.864,20.084 12.981,23.792 Z'/%3E%3C/svg%3E")
	&__inp:checked + &__text
		&::after
			opacity 1
