.box-bubble
	s = selector()
	position relative
	display inline-block
	vertical-align top
	margin-left 10px
	z-index 10
	&:hover
	&.is-active
	&.is-opened
		color #fff
		{s}__icon
			background $green
			transition background .2s .1s
		{s}__body
			opacity 1
			visibility visible
			transition opacity .2s .1s ease-out, visibility 0s 0s

	&__icon
		display block
		size 20px
		background #57524c
		text-align center
		color #fff
		line-height 21px
		font-size 14px
		border-radius 10px
		cursor pointer
		transition background .2s .2s
		&:hover
		&.isActive
			background $green


	&__body
		box-vertical()
		position absolute
		bottom 100%
		left 100%
		size 220
		margin 0 0 -18px -40px
		background rgba($green, .95)
		text-align center
		font-weight 500
		border-radius 110px
		opacity 0
		visibility hidden
		transition opacity .2s .2s ease-out, visibility 0s .4s
		box-shadow 1px 3px 8px rgba(#12110c, .15)
	&__inner
		display inline-block
		vertical-align middle
		max-width 99%
		padding 0 30px
		box-sizing border-box
		color #fff
		a
			border 0 !important
		.icon
			margin-left 5px
			margin-top 2px
		&:hover
			color #fff

	&--small
		{s}__body
			size 100
			margin 0 0 -6px -25px
			box-shadow 1px 3px 8px rgba(#12110c, .15)
		{s}__inner
			padding 5px  5px 0
			line-height 1.2
	&--down
		{s}__body
			bottom auto
			top 100%
			margin -6px 0 0 -25px


