.thickboxOpen
	iframe
		display none
// Layout
#sk-box-overlay
	position absolute
	z-index 990
	width 100%
	height 100%
	top 0
	left 0
	min-height 100%
	background-color #54504b
	opacity .97
	padding 0
	overflow hidden
#sk-box-window
	// background transparent url($img'bg/ajax-loader.gif') 50% 50% no-repeat
	z-index 999
	position absolute
	top 10px
	left 50%
	width 400px
	margin 50px 0 0
	border none
* html #sk-box-window
	position absolute

// content
#sk-content
	min-height 200px
	text-align left
	position relative
	iframe
		display block
#sk-box-title
	display block
	white-space nowrap
	overflow hidden
	text-overflow ellipsis
	margin 0 200px 0 0
	font-size 20px
	font-weight 300
	text-transform uppercase
	@media(max-width 749px)
		margin-right 30px
#sk-box-title-wrap
	position relative
	padding 23px 21px 19px
	background rgba(#3b3b3b, .9)
	overflow hidden
	width 100%
	color #fff
	box-sizing border-box
	min-height 66px
	line-height 24px
	border-radius 2px 2px 0 0
#sk-box-image
	display block
	padding 0
	text-align center
	img
		max-width 100%
		height auto
		width auto
#sk-content-wrap
	position relative

// pager
#sk-box-pages
	display none !important
#sk-box-pager
	position relative
	margin 0
	background #f8f8f8
	height 67px
	border-radius 0 0 2px 2px
	#sk-box-prev
	#sk-box-next
		text-hide()
		position absolute
		left 13px
		top 0
		bottom 0
		width 25px
		z-index 1
		transition opacity .2s
		span
			sprite2x-thickbox-left-size()
			position absolute
			top 50%
			left 50%
			margin-top (@height/-2)
			margin-left (@width/-2)

		&:hover
			opacity .5
	#sk-box-next
		left 44px
		span
			sprite2x-thickbox-right-position()

	// @media $retina
	// 	#sk-box-prev
	// 	#sk-box-next
	// 		span
	// 			background-image url($img'bg/thickbox2x.png')
	// 			background-size 150px 50px

// close
.sk-box-close
	position absolute
	top 22px
	right 20px
	font-size 20px
	font-weight 500
	text-transform uppercase
	color #474747
	text-decoration none
	@media(max-width 749px)
		font-size 0px
		color rgba(0,0,0,0)
	&:hover
		text-decoration none
		color @color
	.icon
		margin-left 10px
		margin-top -6px
	&--header
		color #fff
		&:hover
			color @color

// ie
#sk-box-HideSelect
	z-index 99
	position absolute
	top 0
	left 0
	right 0
	bottom 0
	background-color #fff
	border none
	opacity 0
	margin 0


@media(max-width 999px)
	#sk-box-window
		width calc(100% - 40px) !important
		margin-left 0 !important
		left 20px
		right 20px
