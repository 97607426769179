@import "core/grid.styl"
@import "core/icons.styl"
@import "core/sprites-retina.styl"
@import "core/sprites.styl"
@import "core/icons-sprite.styl"

@import "core/forms/*"
@import "core/u-decorated.styl"
@import "core/u-description.styl"
@import "core/ul.styl"
@import "core/thickbox.styl"
@import "core/margins.styl"
@import "core/headline.styl"
@import "core/title.styl"
@import "core/annot.styl"

@media(max-width 1599px)
	img
		max-width 100%
		height auto
