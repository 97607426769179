/*!
 *	Simply fast class
 */
.reset
	border none
	margin 0
	padding 0
	background none
	box-shadow none
	td
	th
		border none
		background none
		padding 0
	> li
	> dt
	> dd
		margin 0
		padding 0
		background none
		border none
		float none
		width auto

// float
.l
	float left
.r
	float right

// clear
.cb
	clear both
.cl
	clear left
.cr
	clear right
.cfx
	zoom 1
	&:after
	&:before
		content ''
		display table
		clear both
.break
	height 1px
	font-size 1px
	line-height 1px
	clear both
	overflow hidden
	visibility hidden
	display block
	margin 0 !important
	padding 0 !important
	width 100%
ctx()
	zoom 1
	display table-cell
	.ie7 &
		display block
	&:after
		clear both
		display block
		visibility hidden
		overflow hidden
		height 0px !important
		line-height 0px
		font-size xx-large
		content " x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x x "
.ctx
	ctx()


// hide
.hide
.js .jsHide
	display none
.out
.js .jsOut
	position absolute
	left -5000px
	top -5000px
.vhide
	position absolute
	height 1px
	width 1px
	overflow hidden
	border 0
	clip rect(0 0 0 0)
	margin -1px
	padding 0

// align
.left
	text-align left
.right
	text-align right
.center
	text-align center

// transform
.lower
	text-transform lowercase
.upper
	text-transform uppercase

// weight
.bold
	font-weight bold
.thin
	font-weight normal
.medium
	font-weight 500

// style
.italic
	font-style italic
.normal
	font-style normal

// valign
.top
	vertical-align top
.middle
	vertical-align middle

// size
.big
	font-size 18px
.bigger
	font-size 20px
.small
	font-size 15px
.xsmall
	font-size 13px

// color
.is-error
.error
.red
	color #cc0000 // $red
.green
	color #678713 // $green
.grey
	color #999 // $grey

// nowrap
.nowrap
	white-space nowrap
.text-overflow
	display block
	white-space nowrap
	overflow hidden
	text-overflow ellipsis
