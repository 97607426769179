.u-decorated
	position relative
	margin-bottom 3.5em
	padding-left 50px
	&:before
		content ''
		position absolute
		top -5px
		left 0
		bottom -10px
		width 10px
		background #ededed
		border-radius 2px

	h2&
	h3&
		margin-top 1.5em
		margin-bottom 2.2em
		&:before
			bottom -5px

	@media(max-width 999px)
		h2&
		h3&
			margin-top .5em
			margin-bottom 1em

	@media(max-width 749px)
		padding-left 25px
		&::before
			width 5px
