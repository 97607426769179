.box-services
	margin-bottom 29px
	letter-spacing -0.01em
	.grid
		margin-bottom -21px
	.grid__cell
		margin-bottom 21px
	&__annot
		//
	p
		padding-left 52px
		letter-spacing -0.01em
	&__heading
		position relative
		margin-bottom 13px
		padding-left 52px
		line-height 24px
		font-size 18px
		font-weight 500
		.icon
			position absolute
			left 0
			top 5px

	@media(max-width 749px)
		p
			margin 0
		&__heading
		p
			padding-left 35px
