.box-carousel
	position relative
	padding 45px 0
	.box-side-content &
		ul
			text-align center
			li
				background none
				margin-bottom 0
	.box-side-content__image
		margin-left 0
		margin-right 0
		+ p
			margin-bottom 0
	.box-side-content__heading
		&:before
			margin-right -50px
		&:after
			margin-left -50px
	&__list
		@extend $reset-ul
		margin 0 -20px
	&__item
		@extend $reset-ul-li
		padding 0 0 20px
	&__prev
	&__next
		text-hide()
		position absolute
		top 0
		left 0
		width 100%
		height 45px
		line-height 45px
		text-align center
		border 0 !important
	&__next
		top auto
		bottom 0

	@media(max-width 999px)
		.cycle-carousel-wrap
			width 100%
		.box-side-content &__item
			width 100%
