.box-modal
	s = selector()
	ul
		@extend $reset-ul
	li
		@extend $reset-ul-li
	h3
		color #474747
		margin 30px 0 13px
		font-size 22px
		font-weight 500
		font-family $fontRoman
	&__header
		padding 23px 25px 19px
		border-radius 2px 2px 0 0
		&--pink
			background rgba($pink, .85)
		&--green
			background rgba($green, .85)
		&-headline
			margin-top 0
			margin-right 200px
			margin-bottom 0
			font-size 20px
			color #fff
			text-transform none
	&__info
		background #ededed
		padding 20px 30px
		font-size 15px
		text-align center
		clearfix()
		p
			margin-bottom 1.5em
			&:last-child
				margin-bottom 0
		&-main
			width 470px
			float left
			&--cooperator
				p
					margin-bottom 14px
	&__image
		margin-left 500px
		margin-right -30px
		text-align right
	&__name
		position relative
		display block
		padding 0 70px
		font-size 20px
		font-weight 500
		&:before
		&:after
			content ''
			position absolute
			top 50%
			width 50px
			height 1px
			margin-top -2px
			background #d1d1d1
		&:before
			left 0
		&:after
			right 0
	&__body
		padding 6px 30px 50px
		background #f8f8f8
		border-radius 0 0 2px 2px
		.form-std:first-child
			margin-top 20px
	&__close
		position absolute
		right 20px
		font-size 20px
		font-weight 500
		text-transform uppercase
		color #474747
		&:hover
			color @color
		.icon
			margin-left 10px
			margin-top -6px
		&--header
			color #fff
			top 20px
			&:hover
				color @color
	&--standalone
		letter-spacing -0.01em
		{s}__info
			display flex
			justify-content space-between
			padding 35px
		{s}__info-main
			width 530px
			flex-grow 1
		{s}__image
			position relative
			margin-left 35px
			margin-right -35px
			flex-grow 0
			flex-shrink 0
			img
				border-radius 2px 0 0 2px
		{s}__image-wrap
			position relative
			display block
		{s}__info-main
			align-self center
			a
				color #c1275e
				&:hover
					border-color #c1275e
		{s}__flag
			position absolute
			top 50%
			right 0px
			width 152px
			height 37px
			line-height 37px
			background-color #c1275e
			color #fff
			text-align center
			transform-origin 100% 0
			transform rotate(-90deg) translate(50%, -100%)
		{s}__body
			background none
			padding 14px 12px 30px
		{s}__name
			font-size 19px

	@media(max-width 999px)
		&__header-headline
			margin-right 25px
		&__close
			font-size 0
		&__body
		&__info
			padding-left 20px
			padding-right 20px
		&__info
			overflow hidden
			display flex
			flex-direction column
		&__info-main
		&__image
			float none
			margin 0
			width auto
		&__info-main
			width 100%
			order 3
		&__image
			margin 0 0 20px !important
			text-align center
		&__name
			padding-left 0
			padding-right 0
			&::before
			&::after
				display none

		&--standalone &__info
			flex-direction column
			padding 20px
		&--standalone &__info-main
			width 100%
		&--standalone &__image-wrap
			display inline-block
			vertical-align top
			img
				margin-top -20px
				border-radius 0 0 2px 2px
		&--standalone &__body
			padding 0
		&--standalone &__flag
			display none

