/* Titles*/
.h-logo
	position relative
	font-size 22px
	font-family $fontRoman
	text-transform uppercase
	letter-spacing -.025em
	margin-bottom 35px
	padding-left 75px
	min-height 50px
	&:before
		sprite2x-icon-logo-bw-size()
		image-rendering optimizeQuality
		content ''
		position absolute
		top 0
		left 0
	span
		position absolute
		top 50%
		transform translateY(-50%)