.menu-header
	float right
	height 110px
	padding 5px 30px 0
	box-sizing border-box
	background #f2f1f1
	font-size 14px
	line-height 1.2
	white-space nowrap
	text-transform uppercase
	display flex
	flex-direction columns
	align-items center
	&__list
		@extend $reset-ul
	&__item
		@extend $reset-ul-li
		margin-top 20px
	&__link
		color $colorLink
		text-transform uppercase
		&:hover
			color $colorHover
	.icon
		margin-right 20px
	.icon--heart
		top -4px
	.icon--comment
		top -2px

	@media(max-width 749px)
		height 92px
		float none
		position absolute
		right 20px
		top 0
		padding 0
		background none
		font-size 12px
		&__item
			margin-top 10px
		.icon
			margin-right 10px

	@media(max-width 339px)
		.icon
			display none
