.menu-main
	position relative
	font-family $fontNormal
	font-size 14px
	line-height 16px
	z-index 2
	.row-main
		max-width 1100px
	&__list
		@extend $reset-ul
		display flex
		justify-content space-around
		background rgba(#54504b, .8)
		box-shadow 0 2px 10px rgba(#12110c, .25)
		border-radius 2px
	&__item
		@extend $reset-ul-li
		padding 0 30px
		text-align center

	&__link
		position relative
		display inline-block
		vertical-align top
		padding 22px 15px 19px
		color #fff
		text-decoration none
		text-transform uppercase
		font-weight 500
		&:after
			content ''
			position absolute
			left 50%
			bottom 0
			width 70px
			height 2px
			margin-left (@width/-2)
			transition background .2s ease-out
		&--active
			cursor default
			&:after
				background #71ba03
		&:hover
			color #fff
			text-decoration none
			&:after
				background #71ba03
	@media(max-width 999px)
		&__item
			padding 0
	@media(max-width 899px)
		display none
