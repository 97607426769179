.menu-sidebar
	s = selector()

	position relative
	padding 5px 0 1px
	background #57524c
	border-radius 0 0 2px 2px
	z-index 3
	font-size 14px
	text-align center
	&:before
		content ''
		position absolute
		bottom 100%
		left 0
		width 100%
		height 5px
		background rgba(#3b3b3b, .7)
		border-radius 2px 2px 0 0
	&__more
		border-radius 0
		&:after
			content ''
			position absolute
			top 100%
			left 0
			width 100%
			height 5px
			background rgba(#3b3b3b, .7)
			border-radius 0 0 2px 2px

	&__3rows
		{s}__item
			&:nth-child(n+7)
				{s}__link
					&--active:after
						display none

			&:nth-child(n+12)
				{s}__link
					&--active:after
						display block
						top 100%
						bottom 0
						margin-top 8px
						z-index 2


	&__list
		@extend $reset-ul
		display inline-flex
		vertical-align top
		flex-wrap wrap
		text-align center
		width 784px
		background url('../img/bg/menu-side.png') 0 0 repeat
	&__item
		@extend $reset-ul-li
		width 166px
		margin 0 15px 4px
		box-sizing border-box
		background #57524c
		&--heading
			display none
			margin-bottom 26px
			font-size 14px
			color #fff
			text-align center
			text-transform uppercase
			a
				color #fff
		&:nth-child(n+7)
			{s}__link
				&--active:after
					top 100%
					bottom 0
					margin-top 8px
					z-index 2

	&__link
		position relative
		display block
		padding 0px 10px 0
		line-height 47px
		text-align center
		color #ededed
		font-weight 500
		border-radius 2px
		transition background .2s ease-out
		&--active
		&:hover
			color @color
			background #676159
		&--active:after
			content ''
			position absolute
			left 50%
			bottom 100%
			width 70px
			height 2px
			margin-left (@width/-2)
			margin-bottom 8px
			background $green


	&__inner
		display inline-block
		vertical-align middle
		line-height 1.2

	@media(min-width 1000px)
		&__list
			width 980px

	@media(max-width 1439px)
		.show-large
			display none

	@media(min-width 1440px)
		body:not(.page-com) &
			margin 0 -20px 2.375em
			padding 24px 18px 30px
			font-size 16px
			&:before
				display none
			&:first-child
				margin-top -20px
			&__list
				display block
				width auto
				background none
			&__item
				background none
				display block
				width auto
				margin 0 0 4px
				padding 0
				&--heading
					display block
					margin-bottom 26px

			&__link
				padding 0 20px
				line-height 57px
				&--active:after
					display none !important

	@media(max-width 899px)
		display none

