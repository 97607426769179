opacity(n, $ie = $support-for-ie)
	opacity n
	if $ie
		filter unquote('progid:DXImageTransform.Microsoft.Alpha(Opacity=' + round(n * 100) + ')')

placeholder()
	&::-moz-placeholder
		{block}
	&::-webkit-input-placeholder
		{block}
	&:-ms-input-placeholder
		{block}

tap-highlight-color()
	$vendors = webkit
	vendor('tap-highlight-color', $vendors, arguments)

touch-callout()
	$vendors = webkit
	vendor('touch-callout', $vendors, arguments)

