.box-articles
	margin 0 0 70px
	&__item
		padding 40px
		background #f1f1f1
		display flex
		margin 0 0 40px
		h3
		p
			margin 0 0 30px
	&__img
		flex 0 0 auto
		order 2
		margin-left 40px
		margin-right -40px
		img
			border-radius 2px 0 0 2px
	&__title
		font-family $fontMedium
		font-size 20px
		line-height 1.4
	&__content
		> :last-child
			margin-bottom 0

	@media(max-width 999px)
		&__item
			padding 20px
		&__img
			margin-left 20px
			margin-right -20px

	@media(max-width 749px)
		margin 0 0 60px
		&__item
			flex-direction column
			margin 0 0 20px
		&__img
			order 0
			margin 0 auto 20px
			img
				margin-top -20px
				border-radius 0 0 2px 2px
