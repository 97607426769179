.box-background
	margin-bottom 50px
	padding 44px 45px 50px
	background-color #f5f5f5
	letter-spacing -0.01em
	border-radius 2px

	a
		color #c1275e
		&:hover
			border-color #c1275e !important
	p
	ul
		margin-bottom 40px
	ol
		li
			margin-bottom 40px
			padding-left 43px
	:last-child
		margin-bottom 0
	&--image
		overflow hidden
		h2
			margin-top 0
		.box-image
			display block
			float right
			margin 0 -45px 40px 40px
			width 52%
			max-width: 349px
			img
				border-radius 2px 0 0 2px
		img
			width 100%
			height auto

	@media(max-width 999px)
		ol
			li
				margin-bottom 20px
				padding-left 20px

	@media(max-width 749px)
		padding 20px
		.box-image
			float none
			text-align center
			margin -20px auto 20px
			img
				border-radius 0 0 2px 2px
