.menu-mobile
	position absolute
	left 0
	right 0
	top 92px
	z-index 100
	background rgba(#54504b, .97)
	box-shadow 5px 5px 10px rgba(#000, .25)
	&__tool
		position relative
		background none
		border none
		margin 0
		display block
		width 100%
		font-size 14px
		padding 17px 60px 17px 20px
		text-align left
		text-transform uppercase
		color #fff
		font-family $fontMedium
		box-sizing border-box
		&::before
		&::after
			content ''
			position absolute
			top 50%
			right 20px
			width 20px
			height 2px
			background #fff
			margin -1px 0 0
			box-shadow 0 6px 0 #fff, 0 -6px 0 #fff
			transition box-shadow 0.3s 0.3s, transform 0.3s
		.menu-mobile--open &
			&::before
			&::after
				transform rotate(45deg)
				box-shadow 0 0 0 #fff, 0 0 0 #fff
				transition-delay 0s, 0s
			&::after
				transform rotate(-45deg)
	&__list
		font-size 14px
		padding 20px 0 40px
		font-family $fontMedium
		display none
		.menu-mobile--open &
			display block
	&__item&__item
		margin-top 10px
	&__link
		display block
		text-transform uppercase
		padding 10px 20px
		color #fff
		position relative
		&:hover
			color #fff
		&::before
			content ''
			position absolute
			left 0
			top 5px
			bottom 5px
			width 4px
			background #71ba03
			opacity 0
		&.is-active
			&::before
				opacity 1
		&--toggle
			display flex
			padding-right 0
			> span:first-child
				flex 1 1 auto
				display block
	&__expand
		flex 0 0 auto
		width 50px
		position relative
		display block
		&::before
		&::after
			content ''
			position absolute
			left 10px
			right 20px
			top 50%
			height 2px
			background #fff
			transition transform .3s
		&::after
			transform rotate(90deg)
		.is-expanded &
			&::after
				transform rotate(0)


	&__sub
		&-list
			display none
			.is-expanded &
				display block
		&-link
			padding 10px 20px 10px 40px
			display block
			color #fff
			&.is-active
				background #676159

	@media(min-width 750px)
		top 110px

	@media(min-width 900px)
		display none
