/**
 * @license
 * MyFonts Webfont Build ID 3203396, 2016-04-13T05:48:48-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirLTPro-Medium by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir-pro/pro-65-medium/
 *
 * Webfont: AvenirLTPro-Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir-pro/pro-35-light/
 *
 * Webfont: AvenirLTPro-Book by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir-pro/pro-45-book/
 *
 * Webfont: AvenirLTPro-Heavy by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir-pro/pro-85-heavy/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3203396
 * Licensed pageviews: 250,000
 * Webfonts copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2007 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced,
 *
 * © 2016 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3111e1");


@font-face {font-family: 'AvenirLTPro-Medium';src: url('../font/3111E1_0_0.eot');src: url('../font/3111E1_0_0.eot?#iefix') format('embedded-opentype'),url('../font/3111E1_0_0.woff2') format('woff2'),url('../font/3111E1_0_0.woff') format('woff'),url('../font/3111E1_0_0.ttf') format('truetype');}


@font-face {font-family: 'AvenirLTPro-Light';src: url('../font/3111E1_1_0.eot');src: url('../font/3111E1_1_0.eot?#iefix') format('embedded-opentype'),url('../font/3111E1_1_0.woff2') format('woff2'),url('../font/3111E1_1_0.woff') format('woff'),url('../font/3111E1_1_0.ttf') format('truetype');}


@font-face {font-family: 'AvenirLTPro-Book';src: url('../font/3111E1_2_0.eot');src: url('../font/3111E1_2_0.eot?#iefix') format('embedded-opentype'),url('../font/3111E1_2_0.woff2') format('woff2'),url('../font/3111E1_2_0.woff') format('woff'),url('../font/3111E1_2_0.ttf') format('truetype');}


@font-face {font-family: 'AvenirLTPro-Roman';src: url('../font/3111E1_3_0.eot');src: url('../font/3111E1_3_0.eot?#iefix') format('embedded-opentype'),url('../font/3111E1_3_0.woff2') format('woff2'),url('../font/3111E1_3_0.woff') format('woff'),url('../font/3111E1_3_0.ttf') format('truetype');}


//@font-face {font-family: 'AvenirLTPro-Heavy';src: url('../font/3111E1_4_0.eot');src: url('../font/3111E1_4_0.eot?#iefix') format('embedded-opentype'),url('../font/3111E1_4_0.woff2') format('woff2'),url('../font/3111E1_4_0.woff') format('woff'),url('../font/3111E1_4_0.ttf') format('truetype');}
@font-face {
	font-family: 'AvenirLTPro-Heavy';
	src: url('../font/36E85A_0_0.eot');
	src: url('../font/36E85A_0_0.eot?#iefix') format('embedded-opentype'),
	url('../font/36E85A_0_0.woff2') format('woff2'),
	url('../font/36E85A_0_0.woff') format('woff'),
	url('../font/36E85A_0_0.ttf') format('truetype');
}
