.box-contact-main
	background #ededed
	padding 60px 80px
	margin 0 0 30px
	overflow hidden
	&__contact
		text-align center
		font-size 30px
		font-family $fontMedium
		letter-spacing -0.01em
		margin 0
		a
			display inline-block
			line-height: 1
	&__title
		text-align center
		margin 0 0 10px
	&__desc
		margin 0

	@media(max-width 999px)
		padding 40px

	@media(max-width 749px)
		padding 20px
		&__contact
		&__title
			font-size 20px
		&__contact
			margin-bottom 20px

	@media(min-width 750px)
		padding-left 0
		padding-right 0
		.grid
			display flex
			align-items center
			margin-left 0
			justify-content space-evenly
		.grid__cell
			width auto
			max-width 46%
			border-left-width 0
