.box-gallery
	s = selector()

	& + &
		margin-top 40px

	img
		width 100%
		height auto

	&__header
		margin 0
		padding 23px 25px 19px
		background #eee
		font-size 20px
		font-weight 300
		text-transform uppercase
	&__desc
		padding 0 25px 19px
		font-size 15px
		margin -9px 0 0
		background #eee
	&__bottom
		height 66px
		background #eee
	&__list
		@extend $reset-ul
		inline-list(33.333%, $fontSize)
	&__item
		@extend $reset-ul-li
		position relative
	&__link
		display block
		color #fff
		border 0 !important
		&:hover
			color #fff
			{s}__overlay
				opacity 1
				visibility visible
				transition opacity .2s ease-out, visibility 0s
			.icon
				opacity 1
				transform translateY(0)
	&__overlay
		position absolute
		top 0
		left 0
		width 100%
		height 100%
		background rgba(#3b3b3b, .8)
		font-size 16px
		text-align center
		opacity 0
		visibility hidden
		transition opacity .2s ease-out, visibility 0s .2s
		display flex
		align-items center
		justify-content center
		&-inner
			position relative
			padding 0 10px
		&-main
			display inline-block
			vertical-align top
			margin-bottom -3px
			font-size 24px
			font-weight 300
			text-transform uppercase
		.icon
			margin-top 6px
			opacity 0
			transform translateY(-5px)
			transition transform .4s .25s, opacity .4s .25s ease-in

	&--home
		{s}__item
			width 25%
		{s}__header
			font-size 30px
			text-align center
			padding 60px 20px 55px

	&--sm
		margin-bottom 60px

	@media(max-width 1279px)
		&--home &__item
			width 33.333%
			&:nth-child(n+7)
				display none

	@media(max-width 749px)
		&__bottom
			height 0
		&__header
		&__desc
			padding-left 20px
			padding-right 20px
		&__list
			background #eee
		&__item
			width 50%
		&--home &__header
			padding 40px 20px 35px
			font-size 22px
			font-family $fontRoman

	@media(max-width 479px)
		&__item
			width 100%
		&--home &__list
			overflow hidden
			overflow-x auto
			white-space nowrap

	@media(min-width 750px)
		&--sm &__item
			width 50%

	@media(min-width 1280px)
		&--sm &__item
			width 25%
