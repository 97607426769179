.footer
	font-size 15px
	line-height 1.4
	padding 67px 0 0
	box-shadow 0 0 10px rgba(#12110c, .3)
	position relative
	&:after
		content ""
		position absolute
		left 0
		bottom 27px
		right 0
		border-bottom 1px solid #424140
	.grid__cell
		font-size @font-size
	.row-main
		max-width 1390px

	&__main
		margin-bottom 21px
	&__heading
		letter-spacing -0.01em
		margin-bottom 37px
		a
			color #fff
			&:hover
				color #9e9b98
		.grid > &
			margin-bottom 3em

	&__item
		background-image url($img'bg/li-footer.png')
		background-position 0 .25em
		background-position 0 5px
		line-height 20px
		padding-left 35px
		margin-bottom 24px
	&__link
		color #8f8c89
		letter-spacing -0.01em
		line-height 20px
		&:hover
			color #c7c3bf

	&__copyright
		margin-bottom 46px
		text-align right
		letter-spacing 0.02em
		font-size 12px
		color #726f6c
		.as-link
			color #8f8c89
			&:hover
				color #c7c3bf
	&__line
		overflow hidden
		padding-top 24px
		padding-bottom 24px
		border-top 1px solid #484848
		p
			margin-bottom 0
	&__r
		margin-bottom -15px
		overflow hidden
		font-size 0
		text-align right
		a
			display inline-block
			vertical-align top
			margin-left 15px
			margin-bottom 15px
			color #9e9e9e
			background-color #3d3b37
			font-size 12px
			line-height 15px
			padding 9px 16px 10px
			border-top-left-radius 10px
			border-bottom-right-radius 10px
			font-weight 300
			&:hover
				color #c7c3bf
	&__l
		margin-right 50px
		font-size 0
		white-space nowrap
		a
			display inline-block
			vertical-align middle
	&__bottom
		margin-bottom 52px
		display flex
		align-items center
		justify-content space-between
	&__logo
		display inline-block
		vertical-align top
		position relative

	&__logo-leticia
		margin-left 66px
		width 89px
		height 74px
		.footer__logo-hover
			position absolute
			left 0
			top 0
			opacity 0
			transition opacity .3s
		&:hover
			.footer__logo-hover
				opacity 1
	&__logo-vascumed
		width 115px
		height 72px
		.footer__logo-hover
			position absolute
			left 0
			top 0
			opacity 0
			transition opacity .3s
		&:hover
			.footer__logo-hover
				opacity 1
	@media(min-width 1280px)
		&__heading
			& + &
				margin-top 3em
			.grid > &
				margin-bottom 1em

	@media(max-width 1390px)
		&__r
			width 700px

	@media(max-width 999px)
		&__item
			padding-left 0
			background none

	@media(max-width 749px)
		&__heading
		&__item
			margin-bottom 10px
		&__list
		.grid > &__heading
			margin-bottom 20px
		&__group
			margin 0 0 10px
		&__heading a:hover
			color #fff
		&__toggle
			&::after
				content ''
				width 4px
				height 10px
				display inline-block
				vertical-align middle
				margin-left 10px
				background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 10'%3E%3Cpath fill='%23ffffff' d='M0.9,0.1C0.9,0.1,0.8,0,0.7,0C0.6,0,0.5,0.1,0.5,0.1L0.1,0.7C0,0.9,0,1.2,0.1,1.3l2.2,3.3 c0.1,0.2,0.1,0.5,0,0.7L0.1,8.7C0,8.8,0,9.1,0.1,9.3l0.4,0.5C0.5,9.9,0.6,10,0.7,10c0.1,0,0.2-0.1,0.2-0.1l3-4.4 C4,5.2,4,4.8,3.9,4.6L0.9,0.1z'/%3E%3C/svg%3E%0A")
				transition transform .3s
			.is-expanded &
				&::after
					transform rotate(90deg)
		&__list
			display none
			.is-expanded &
				display block
		&__bottom
			flex-direction column
			justify-content flex-start
			align-items flex-start
		&__l
			margin 0 0 40px
		&__r
			width auto
			text-align left
			margin-left -15px
		&__copyright
			text-align center


