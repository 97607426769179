.icon
	position relative
	display inline-block
	vertical-align middle
	overflow hidden
	transition transform .3s
	a:hover &
		&:before
		&:after
			transform translateY(calc(-100% - 5px))
	&:before
	&:after
		content ''
		position relative
		display block
		width 100%
		height 100%
		transition transform .3s

	&:after
		top 5px

	&--popup
		sprite2x-icon-popup-size()
	&--heart
		sprite2x-icon-heart-sizeonly()
		&:before
			sprite2x-icon-heart()
		&:after
			sprite2x-icon-heart-hover()
	&--comment
		sprite2x-icon-comment-sizeonly()
		&:before
			sprite2x-icon-comment()
		&:after
			sprite2x-icon-comment-hover()
	&--check
		sprite2x-icon-check-size()
	&--cross
		sprite2x-icon-cross-size()
	&--logo
		sprite2x-icon-logo-size()
	&--logo-m
		sprite2x-icon-logo-m-size()
	&--docinfo
		sprite2x-docinfo-size()
	&--close
		sprite2x-icon-close-sizeonly()
		&:before
		&:after
			sprite2x-icon-close()
	&--close-white
		sprite2x-icon-close-white-sizeonly()
		&:before
		&:after
			sprite2x-icon-close-white()

	&--arr-down
		sprite2x-arr-down-size()
		a:hover &
			transform translateY(5px)
	&--arr-left-pink
		sprite2x-arr-left-pink-size()
	&--arr-down-white
		sprite2x-arr-down-white-size()
	&--arr-down-btn
		sprite2x-arr-down-btn-size()
	&--arr-right-more
		sprite2x-arr-right-more-size()
	&--arr-right
		sprite2x-arr-right-sizeonly()
		&:before
			sprite2x-arr-right()
		&:after
			sprite2x-arr-right-hover()
	&--arr-car-up
		sprite2x-arr-car-up-size()
	&--arr-car-down
		sprite2x-arr-car-down-size()
	&--arr-right-pink
		sprite2x-arr-right-pink-size()
	&--anim
		overflow hidden
		&:before
		&:after
			content ''
			display block
			width 100%
			height 100%
	&--glass
		sprite2x-icon-glass-size()
	&--arr-down-pink
		sprite2x-arr-down-pink-size()
	&--check-m
		sprite2x-icon-check-m-size()
	&--close-m
		sprite2x-icon-close-m-size()
	&--leticia
		sprite2x-leticia-size()
	&--leticia-hover
		sprite2x-leticia-hover-size()
	&--vascumed
		sprite2x-vascumed-size()
	&--vascumed-hover
		sprite2x-vascumed-hover-size()
