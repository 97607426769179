.box-text-image
	margin-bottom 47px
	overflow hidden
	letter-spacing -0.01em
	.box-image
		display block
		float right
		margin-left 40px
		margin-bottom 40px
		width 50.6%
		max-width: 371px
	img
		width 100%
		height auto	