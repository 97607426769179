.box-map
	margin-top 3em
	margin-bottom 1em
	&__header
		padding 23px 21px 19px
		background #57524c
		overflow hidden
		width 100%
		color #fff
		box-sizing border-box
		line-height 24px
		display flex
		flex-wrap wrap
		justify-content space-between
		gap 5px 20px
	&__heading
		@extend h3
		margin 0
		font-size 20px
	&__link
		color #fff
		border 0 !important
		&:hover
			color #e3e3e3
		.icon
			margin-left 10px
	&__map
		height 400px
		background #ccc
		img
			max-width none !important

	@media(min-width 1440px)
		margin-top 5em
		margin-bottom 5em

	@media(min-height 700px)
		&__map
			height 535px

	@media(max-width 749px)
		&__map
			max-height 70vh
