.b-cookie {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	display: none;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	min-width: 0;
	height: 100%;
	min-height: 0;
	font-size: 14px;

	h2.h3 {
		font-size 16px;
		margin-bottom: 1em;
	}

	.btn {
		> span {
			background: #625d57;
			border: none;
			border-radius: 10px 0;
			transition: background-color 0.3s;
		}
		&:hover {
			> span {
				background #8fc43d
			}
		}
	}

	.as-link {
		color: #b2afab;
		&:hover {
			color: #fff;
		}
	}

	&__bg {
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(89, 85, 80, 0.25);
	}
	&__box {
		position: relative;
		display: flex;
		flex: 0 1 auto;
		flex-direction: column;
		width: 920px;
		max-width: 100%;
		min-width: 0;
		max-height: calc(100% - 40px);
		padding: 1.5em;
		background: #595550;
		color #ffffff
		overscroll-behavior: contain;
		margin-bottom 20px
		box-shadow 0 2px 10px rgba(#12110c, .25)
	}
	&__box-inner {
		margin-right: -20px;
		padding-right: 20px;
		overflow-y: auto;
	}

	&__btns {
		display: flex;
		gap: 1.5em;
		justify-content: space-between;
		align-items: center;
		margin-top: 2em;
		p {
			display: flex;
			gap: 10px;
			margin: 0;
		}
	}

	&__option {
		margin-top: 1em;
		margin-bottom: 1em;
		& + & {
			margin-top: 0;
			padding-top: 1em;
			border-top: 1px solid #cccccc;
		}
	}
	&__option-head {
		display: flex;
		align-items: center;
		cursor: default;
		> span {
			position: relative;
			flex: 1 1 auto;
			padding-right: 1.25em;
			padding-left: 1.25em;
			cursor: default;
			&::before {
				content: '';
				position: absolute;
				top: 0.4em;
				left: 0.21em;
				border-top: 0.357em solid transparent;
				border-bottom: 0.357em solid transparent;
				border-left: 0.357em solid currentColor;
			}
			strong {
				cursor: pointer;
			}
		}
	}
	&__option-body {
		display: none;
		padding-top: 1em;
		> *:last-child {
			margin-bottom: 0;
		}
	}

	// states
	html:not([data-cookie-state='settings']) & [data-step='2'],
	html[data-cookie-state='settings'] & [data-step='1'] {
		display: none;
	}

	html[data-show-cookie='true'] & {
		display: flex;
	}

	// when toggle is open (active state)
	&__option-head[data-cookie-toggle='true'] > span::before {
		transform: rotate(90deg);
	}
	&__option-head[data-cookie-toggle='true'] + &__option-body {
		display: block;
	}

	// rwd
	@media ($mdUp) {
		align-items: center;
		padding: 0 1.5em;
		&__box {
			max-width: 700px;
			padding: 2.5em;
		}
	}
	@media (max-width: 520px) {
		&__btns {
			gap: 1em;
			flex-direction: column;
			p {
				flex-direction: column;
				width: 100%;
			}
			.btn {
				display: block;
				width: 100%;
			}
		}
	}
}
