#main
	overflow hidden
	margin-top -57px
	@media(min-width 1440px)
		.page-subpage &
			background url($img'bg/gradient-page.png') no-repeat 310px 113px
	@media(max-width 899px)
		display flex
		flex-direction column
		margin-top 0
		.box-side-content
			order 10
