.ul-large
	&.mb0
		margin-bottom 0
		:last-child
			margin-bottom 0
	li
		position relative
		margin-bottom 25px
		padding-left 65px
	@media(max-width 749px)
		li
			padding-left 30px
		.grid__cell + .grid__cell &
			margin-top 25px
.ul-check
	li
		background none
		&:before
			//sprite2x-icon-check-size()
			sprite2x-icon-check-m-size()
			content ''
			position absolute
			top 0
			top 5px
			left 0
.ul-cross
	li
		background none
		&:before
			sprite2x-icon-cross-size()
			content ''
			position absolute
			top 0
			left 0
.ul-logo
	li
		background none
		position relative
		margin-bottom 20px
		padding-left 80px
		min-height 50px
		&:before
			sprite2x-icon-logo-bw-size()
			image-rendering optimizeQuality
			content ''
			position absolute
			top 0
			left 0
.ul-arrow
	li
		background none
		position relative
		margin-bottom 23px
		padding-left 50px
		&:before
			sprite2x-arr-right-bold-size()
			image-rendering optimizeQuality
			content ''
			position absolute
			top 2px
			left 0
