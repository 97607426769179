.box-person
	display block
	border-radius 2px
	overflow hidden
	background linear-gradient(#f0f0f0, #f5f5f5)
	&:hover
		border 0
		border-color transparent
		text-decoration none
		.box-person__hover
			transform translateX(-100%)
		.box-person__img
			img
				transform scale(1.05)
		.box-person__content
			background #f5f5f5
	&__img
		position relative
		overflow hidden
		max-height 118px
		img
			display block
			width 100%
			transition transform .5s ease-out
	&__hover
		position absolute
		z-index 5
		width 37px
		right -37px
		height 100%
		background-color rgba(193,39,94, .8)
		background url('../img/bg/lupa.png') no-repeat 8px center rgba(193,39,94, .8)
		transition transform .3s ease-out
		.icon
			display none
			position absolute
			top 50%
			left 50%
			transform translate(-50%,-50%)
	&__content
		padding 25px 10px 30px
		text-align center
		font-size 15px
		letter-spacing -0.01em
		color #474747
		line-height 23px
		span
			display block
		.icon
			margin 23px auto 0
	&__heading
		display block
		position relative
		margin 0 0 1px
		padding 0 40px
		font-size 16px
		line-height 1.5
		font-weight 500
		&:before
		&:after
			content ''
			position absolute
			top 50%
			width 50px
			height 1px
			margin-top -2px
			background #d5d5d5
		&:before
			right 100%
			margin-right -37px
		&:after
			left 100%
			margin-left -37px
	&__about
		margin 0
