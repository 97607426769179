// Header
.header
	position relative
	height 110px
	background #f7f7f7 url($img'bg/gradient.png') no-repeat 0 0
	//background linear-gradient(to top, #f8f8f8, #fdfdfd)
	z-index 3
	.row-main
		max-width 1100px
	&__claim
		box-vertical()
		overflow hidden
		height 110px
		margin-bottom 0
		color #6a7e81
		font-size 16px
		line-height 1.2
		letter-spacing -0.02em
		text-align center
		padding 0 20px
		&-inner
			display inline-block
			vertical-align middle
	&__claim-mobile
		display none
	@media(max-width 1523px)
		@media(min-width 900px)
			.row-main
				padding-right 220px

	@media(max-width 749px)
		height 92px
		&__claim
			font-size 13px
			letter-spacing -0.02em
			height auto
			text-align left
			margin-top 5px
			display block
			line-height 1.5
			padding 0
			&::before
				display none
	@media(max-width 530px)
		&__claim
			display none
		&__claim-mobile
			display block
			margin-top 5px
			font-size 13px
			color #6a7e81
			letter-spacing -0.02em

#logo
	box-vertical()
	display block
	font 100%/110px $font
	margin 0
	padding 0
	float left
	a
		text-decoration none
	img
		image-rendering optimizeQuality
	@media(max-width 749px)
		line-height 1
		float none
		padding-top 20px
		img
			width 148px
			height 30px
		&.logo--com
			img
				width 120px
				height 34px
