.box-people
	margin-bottom 78px
	.grid
		margin 0 0 -50px -22px
	.grid__cell
		margin-bottom 50px
		border-left 22px solid transparent

	@media(max-width 749px)
		.grid
			margin 0 0 -20px -20px
		.grid__cell
			margin-bottom 20px
			border-left-width 20px
