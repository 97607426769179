.box-insurance
	padding 30px 30px 50px
	&__list
		@extend $reset-ul
	&__item
		@extend $reset-ul-li
	img
		max-width 100%
		height auto
	@media(max-width 1439px)
		&__list
			display table
			width 100%
		&__item
			display table-cell
			text-align center
			padding 0 20px
		&--large
			display none

	@media(min-width 1440px)
		padding 0
		padding-top 22px
		text-align center
		&__item
			margin-bottom 50px
		&--small
			display none

	@media(max-width 999px)
		padding 40px 20px
		&__list
			display flex
			flex-wrap wrap
			margin 0 0 -20px -40px
			align-items center
			justify-content center
		&__item
			padding 0
			margin 0 0 20px 40px

	@media(max-width 749px)
		&__list
			margin-left -20px
		&__item
			margin-left 20px
		img
			max-width calc(50vw - 50px)
			width auto
			max-height 40px

