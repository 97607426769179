.form-order
	&__branches
		background #ededed
		padding 35px 25px
		text-align center
		font-size 26px
		text-transform uppercase
		&-list
			display inline-block
			vertical-align top
			text-align left
		&-item&-item:not(:first-child)
			margin-top 10px
	&__branch
		display none
		background #fff
		padding 75px 25px 60px
		&.is-active
			display block
		&.is-hidden
			display none
		&-list
			display flex
			justify-content center
			align-items center
			&.is-hidden
				display none
		&-item
			flex 0 0 auto
			&--or
				writing-mode vertical-rl
				text-orientation mixed
				transform rotate(180deg)
				font-style italic
				color #808080
				font-size 15px
		&-link
			background #ededed
			display flex
			flex-direction column
			justify-content center
			align-items center
			position relative
			text-align center
			color #ac2857
			font-family $fontMedium
			font-size 20px
			text-transform uppercase
			width 205px
			height 205px
			padding 25px
			border-radius 50%
			margin 0 7px
			transition background-color .3s
			&:hover
				background darken(#ededed, 5%)
				color #ac2857
		&-text
			&::after
				content ''
				display block
				width 31px
				height 12px
				margin 10px auto
				background-image url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='12'%3E%3Cpath fill='%23ac2857' d='M0.434,2.757 C0.154,2.575 -0.003,2.326 -0.003,2.067 C-0.003,1.807 0.154,1.560 0.434,1.378 L2.105,0.294 C2.675,-0.075 3.591,-0.075 4.162,0.294 L14.402,6.931 C15.011,7.326 15.986,7.326 16.597,6.931 L26.837,0.294 C27.407,-0.075 28.322,-0.075 28.893,0.294 L30.563,1.378 C30.844,1.560 31.001,1.807 31.001,2.067 C31.001,2.326 30.844,2.575 30.563,2.757 L16.869,11.632 C16.109,12.125 14.889,12.125 14.129,11.632 L0.434,2.757 Z'/%3E%3C/svg%3E")
		&-badge
			position absolute
			left -10px
			top -20px
			width 100px
			height 100px
			background rgba($green, .71)
			text-align center
			font-family $fontMedium
			border-radius 110px
			box-shadow 1px 3px 8px rgba(#12110c, .15)
			font-size 13px
			text-transform uppercase
			display flex
			align-items center
			justify-content center
			color #fff
			font-weight 500
		&-note
			font-size 16px
			display block
			color #6b6b6b
			font-family $fontRoman
	&__form
		display none
		&.is-active
			display block
		.form-std
			margin 0
			&__button
				color #ac2857
				&:hover
					color #ac2857
		.inp-select
			background none
		&-back-link
			position relative
			display inline-block
			vertical-align top
			padding-left 40px
			text-transform uppercase
			font-family $fontMedium
			font-size 20px
			color #ac2857
			.icon
				position absolute
				left 0
				top 0
				transition left .3s
			&:hover
				color #ac2857
				.icon
					left -5px

	@media(max-width 999px)
		&__branch
			&-list
				flex-direction column
				gap 5px
			&-item
				&--or
					writing-mode horizontal-tb
					transform rotate(0)


