/*!
 *	Grid
 */
.row
	position relative
	margin 0
	padding 0
	clearfix()

.row-main
	@extend .row
	margin 0 auto
	max-width 1120px
	padding 0 40px
	@media(max-width 999px)
		padding 0 20px

.grid
	@extend $reset-ol
	@extend $grid
	margin-left -45px
	&__cell
		@extend $reset-ol-li
		@extend $grid__cell
		width 100%
		position relative
		border-left 45px solid transparent
		&.size--auto
			width auto
	&--scroll
		@extend $grid--scroll
	&--center
		text-align center
	&--right
		text-align right
	&--middle > &__cell
		vertical-align middle
	&--bottom > &__cell
		vertical-align bottom
	@media(max-width 999px)
		&__cell
			font-size 15px

// selector, separator, columns, rule, coef, otherRules
grid('.size--', '-', 10, 'width')
grid('.push--', '-', 10, 'left')
grid('.pull--', '-', 10, 'left', -1)

grid('.size--', '-', 12, 'width')
grid('.push--', '-', 12, 'left')
grid('.pull--', '-', 12, 'left', -1)

@media(max-width 1439px)
	grid('.size--m-', '-', 10, 'width')
	grid('.push--m-', '-', 10, 'left')
	grid('.pull--m-', '-', 10, 'left', -1)

	grid('.size--m-', '-', 12, 'width')
	grid('.push--m-', '-', 12, 'left')
	grid('.pull--m-', '-', 12, 'left', -1)

@media(max-width 1279px)
	grid('.size--s-', '-', 10, 'width')
	grid('.push--s-', '-', 10, 'left')
	grid('.pull--s-', '-', 10, 'left', -1)

	grid('.size--s-', '-', 12, 'width')
	grid('.push--s-', '-', 12, 'left')
	grid('.pull--s-', '-', 12, 'left', -1)

@media(max-width 999px)
	grid('.size--md-', '-', 12, 'width')
	grid('.push--md-', '-', 12, 'left')
	grid('.pull--md-', '-', 12, 'left', -1)

@media(max-width 749px)
	grid('.size--sm-', '-', 12, 'width')
	grid('.push--sm-', '-', 12, 'left')
	grid('.pull--sm-', '-', 12, 'left', -1)

@media(max-width 479px)
	grid('.size--xs-', '-', 12, 'width')
	grid('.push--xs-', '-', 12, 'left')
	grid('.pull--xs-', '-', 12, 'left', -1)

@media(min-width 1280px)
	.grid__cell
		&--left
			width 16.445999%
		&--middle
			width 20.55749%
			width 20.627177%
		&--right
			width 18.536585%
