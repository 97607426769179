@import "content/*"

/*!
 * Typo
 */
body
	font normal $fontSize/$lineHeight $font
	color #000
	@media(max-width 999px)
		font-size 15px

/* Titles*/
h1
.h1
h2
.h2
h3
.h3
h4
.h4
h5
.h5
h6
.h6
	font-weight 300
	line-height 1.2
	margin 1.5em 0 1em
	@media(max-width 749px)
		margin-top 2em
		margin-bottom .5em

h1
.h1
	font-size 30px
	color $green
	text-transform uppercase
h2
.h2
	font-size 26px
	text-transform uppercase
	margin-bottom 1.4em
	margin-top 3.3em
	@media(max-width 749px)
		margin-top 2em
		margin-bottom .5em
h3
.h3
	font-size 18px
	text-transform uppercase
	margin-bottom 1.8em
	&.low
		margin-bottom .6em
	@media(max-width 749px)
		margin-top 2em
		margin-bottom .5em

h4
.h4
	font-size 16px

h5
.h5
h6
.h6
	font-size 15px

/* Paragraph */
p
	margin 0 0 2.375em
hr
	border none
	border-top 1px solid #ddd
	margin 40px 0
	height 1px
	overflow hidden

/* Blockquote */
blockquote
	margin 0 0 2.375em
	padding 0
	p
		margin 0

/* Links */
a
	color $colorLink
	tap-highlight-color rgba(0,0,0,0);
	text-decoration none
	transition color .3s
	&:hover
		color $colorHover


/* Lists */
ul
ol
	margin 0 0 2.375em
	list-style none
	padding 0
li
	padding 0 0 0 20px
	margin 0 0 .25em
	ol
	ul
		margin .25em 0 0
ul
	li
		background url($img+'bg/li.png') 0 0.3em no-repeat
		//background url($img+'bg/icon-check-m22x13.png') no-repeat 0 5px
ol
	counter-reset item
	li
		background none
		position relative
		&:before
			content counter(item)"."
			counter-increment item
			position absolute
			left 0
			top 0
			font-weight bold
			text-align left

dl
	margin 0 0 2.375em
dt
	font-weight bold
	margin 0
dd
	margin 0 0 .75em
	padding 0

/* Tables */
table
	width 100%
	// clear both
	margin 0 0 2.375em
	empty-cells show
	border-collapse collapse
	border 0
	&.middle
		td
		th
			vertical-align middle
	&.service
		line-height 1.2
		margin-bottom 67px
		td
		th
			height 33px
			height 47px
			padding-top 5px
			padding-bottom 3px
			//padding-top 12px
			//padding-bottom 10px
		.min
			padding-top 21px
			padding-bottom 21px
			font-size 0
			height 13px
			line-height 13px
			text-align center
	.min
		width 1%
		padding-right 0
	@media(max-width 999px)
		&.service
			margin-bottom 40px
	@media(max-width 749px)
		.text-rwd-vertical
			writing-mode vertical-rl
			text-orientation mixed
caption
	text-align left
	padding 0px 0px 20px
	caption-side top
	text-transform uppercase
	font-size 20px
	font-weight 300
td
th
	vertical-align top
	padding 18px 25px 15px
	border 0
	letter-spacing -0.01em
	@media(max-width 999px)
		padding 15px 10px
	@media(max-width 480px)
		padding-left 7px
		padding-right 7px


thead th
	text-align center
	text-transform uppercase
	font-size 16px
	font-weight 300
	padding-bottom 35px
	@media(min-width 1280px)
		font-size 20px
	@media(max-width 749px)
		font-size 15px
		padding 15px 10px
		vertical-align bottom
tbody
	font-size 15px
	th
	td
		background #eeeeee
		background #f2f2f2
		border-top 1px solid #fff
	tr:first-child
		th
		td
			border-top 0
	@media(max-width 749px)
		font-size 13px
		td
			vertical-align middle
			.icon
				transform scale(0.7)
	@media(max-width 480px)
		td:first-child
			font-size 12px
tfoot
	text-align center
	line-height 1.2
	td
		padding-top 40px
	@media(max-width 749px)
		white-space normal
		td
			padding-top 15px
			writing-mode vertical-rl
			text-orientation mixed
			vertical-align middle
			text-align left
			.icon
				display none

/* Images */
img.l
	margin .25em 20px 20px 0
img.r
	margin .25em 0 20px 20px
