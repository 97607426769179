.btn
	inline-block(middle)
	margin 0
	padding 0
	border none
	background none
	text-decoration none
	> span
		position relative
		display block
		border 1px solid #689c1c
		height 38px
		padding 0 20px
		font-size 16px
		line-height 38px
		background #7cba22
		color #fff
		text-decoration none
		text-transform uppercase
		font-family $font
	&:hover
		> span
			background #8fc43d

.as-link
	margin 0
	padding 0
	background none
	border none
	color $colorLink
	tap-highlight-color rgba(0,0,0,0);
	text-decoration none
	transition color .3s
	&:hover
		color $colorHover
