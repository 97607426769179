.box-features
	padding 70px 0 68px
	background #f7f7f7 url($img'bg/gradient.png') no-repeat 50% 0
	//background linear-gradient(to bottom, #fdfdfd, #f7f7f7)
	.grid__cell
		position relative
		text-align center
		&:before
			content ''
			position absolute
			top 3px
			left -22px
			bottom 6px
			width 1px
			background #efefef
		&:first-child:before
			//display none
		&:last-child:after
			content ''
			position absolute
			top 3px
			right -22px
			bottom 6px
			width 1px
			background #efefef

	p
		margin-bottom 0
		&.box-features__icon
			margin-bottom 19px
	&__link
		font-size 13px
		text-transform uppercase
		letter-spacing .001em
		font-weight bold
	&__heading
		margin 27px 0 19px
		font-size 18px
		line-height 1.2
		font-family $fontRoman
		font-weight 500
	&__text
		letter-spacing .001em
		margin-bottom 36px
		p
			line-height 22px
			margin-bottom 8px
	.icon--logo-m
		width 33px
		height 47px

	@media(max-width 999px)
		.grid
			margin-left -20px
			&__cell
				border-left-width 20px

	@media(max-width 749px)
		padding 40px 0
		&__item
			padding-top 80px
			position relative
			&::before
				content ''
				position absolute
				left 50%
				top 40px
				height 1px
				background #e6e6e6
				width 200px
				margin-left -100px
		.grid__cell:first-child &__item
			padding-top 0
			&::before
				display none
		&__heading
		&__text
			margin-bottom 16px


